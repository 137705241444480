import { gql } from "@apollo/client";

export const CREATE_LEASE_RESPONSE = gql`
  mutation Mutation($id: String!, $data: CreateLeaseResponseData) {
    createLeaseResponse(id: $id, data: $data) {
      code
      success
      message
      lease {
        id
        status
      }
    }
  }
`;

export interface CreateLeaseResponseMutationResponse {
  code: string;
  success: boolean;
  message: string;
}
