import { FormContext, FormData } from "./FormContext";
import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

interface Image {
  href: string;
}
interface SpotData {
  address: string;
  carType: number;
  description: string;
  evCharging: boolean;
  minimumLeaseRange: number;
  name: string;
  nickname: string;
  numberOfSpaces: number;
  parkingPass: boolean;
  period: {
    start: string;
    end: string;
  };
  pricing: {
    daily: number;
    weekly: number;
    monthly: number;
  };
  restroom: boolean;
  securityCameras: boolean;
  sheltered: boolean;
  tailgating: boolean;
  image: string;
  images: Image[];
}

const GET_SPOT = gql`
  query GetEditingSpot($spotId: ID!) {
    spot(id: $spotId) {
      address
      carType
      description
      evCharging
      minimumLeaseRange
      nickname
      numberOfSpaces
      parkingPass
      period {
        end
        start
      }
      pricing {
        daily
        weekly
        monthly
      }
      restroom
      securityCameras
      sheltered
      tailgating
      images {
        href
      }
    }
  }
`;

export const ListSpot = () => {
  const { spotId, listingId } = useParams();

  const [formData, setFormData] = useState<FormData>({});
  const [ready, setReady] = useState(spotId == null || spotId === "new");

  const { loading, error, data } = useQuery<
    { spot: SpotData },
    { spotId: string }
  >(GET_SPOT, {
    variables: {
      spotId: spotId ?? "",
    },
    skip: ready,
  });

  useEffect(() => {
    setFormData((formData) => {
      return {
        ...formData,
        propertyId: listingId,
      };
    });
  }, [listingId]);

  useEffect(() => {
    if (ready) return;
    setFormData((formData) => {
      return {
        ...formData,
        address: data?.spot.address,
        carType: data?.spot.carType,
        description: data?.spot.description,
        evCharging: data?.spot.evCharging,
        minimumLeaseRange: data?.spot.minimumLeaseRange,
        nickname: data?.spot.nickname,
        numberOfSpaces: data?.spot.numberOfSpaces,
        parkingPass: data?.spot.parkingPass,
        period: {
          start: data ? new Date(data?.spot.period.start) : undefined,
          end: data ? new Date(data?.spot.period.end) : undefined,
        },
        pricing: {
          daily: data?.spot.pricing.daily,
          weekly: data?.spot.pricing.weekly,
          monthly: data?.spot.pricing.monthly,
        },
        restroom: data?.spot.restroom,
        securityCameras: data?.spot.securityCameras,
        sheltered: data?.spot.sheltered,
        tailgating: data?.spot.tailgating,
        image: data?.spot.images?.[0]?.href,
      };
    });
    setReady(data != null);
  }, [ready, data]);

  return (
    <div className={"bg-white shadow-md p-4 rounded-lg max-w-xl m-auto"}>
      <FormContext.Provider value={[formData, setFormData]}>
        {ready ? <Outlet></Outlet> : <p>Loading</p>}
      </FormContext.Provider>
    </div>
  );
};
