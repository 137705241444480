import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  CREATE_THREAD,
  CreateThreadResponse,
} from "../queries/sendMessageToSpot";

export interface Props {
  recipientId?: string;
  showModal?: boolean;
  onSent?: (threadId: string) => void;
  onCancel?: () => void;
  //onSend: string;
}

export function SendMessageModal({
  recipientId = "",
  showModal = false,
  onSent = () => {},
  onCancel = () => {},
}: Props) {
  const [message, setMessage] = useState("");

  const [sendMessage, { loading, error, data }] = useMutation<{
    createThread: CreateThreadResponse;
  }>(CREATE_THREAD, {
    variables: {
      userId: recipientId,
      message: message,
    },
    onCompleted(data, clientOptions) {
      setMessage("");
      onSent(data.createThread.sentMessage.threadId);
    },
  });

  if (!showModal) return <></>;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
        <div className="bg-white rounded-lg p-6 z-10 w-96 relative">
          <h2 className="text-lg font-medium mb-4">Send Message</h2>
          <textarea
            className="w-full border border-gray-300 rounded-md shadow-sm p-2 mb-4"
            rows={5}
            placeholder="Type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="flex justify-end">
            <button
              disabled={loading}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2"
              onClick={() => sendMessage()}
            >
              {loading ? "Sending..." : "Send"}
            </button>
            <button
              className="bg-gray-300 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-400"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
