const SUV = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.915 87.6686C124.915 88.9986 100.31 90.0836 70 90.0836C39.655 90.0836 15.085 88.9986 15.085 87.6686C15.085 86.3736 39.655 85.2886 70 85.2886C100.31 85.2886 124.915 86.3736 124.915 87.6686Z"
        fill="#67A9F1"
      />
      <path
        d="M25.375 76.469L27.125 70.414L105.91 69.189L108.99 76.154L107.52 79.689L103.25 78.114H91.525L88.375 80.074L83.405 77.484H53.095L45.57 80.144L42.105 78.219H29.68L26.425 80.144L25.375 76.469Z"
        fill="#182830"
      />
      <path
        d="M43.995 80.0736C43.995 84.4836 40.39 88.0536 35.98 88.0536C31.57 88.0536 28 84.4836 28 80.0736C28 75.6636 31.57 72.0586 35.98 72.0586C40.39 72.0586 43.995 75.6636 43.995 80.0736Z"
        fill="#2E363D"
      />
      <path
        d="M41.0901 80.0734C41.0901 82.8734 38.8151 85.1834 35.9801 85.1834C33.1801 85.1834 30.8701 82.8734 30.8701 80.0734C30.8701 77.2384 33.1801 74.9634 35.9801 74.9634C38.8151 74.9634 41.0901 77.2384 41.0901 80.0734Z"
        fill="#93A5A5"
      />
      <path
        d="M40.8101 81.7537L38.6401 81.1587L40.6001 82.2437C40.5534 82.3603 40.4951 82.477 40.4251 82.5937L37.3451 80.8437C37.2051 81.077 37.0184 81.2637 36.7851 81.4037L38.5351 84.4837C38.4184 84.5537 38.3017 84.612 38.1851 84.6587L37.1001 82.6987L37.6951 84.8687C37.5784 84.9153 37.4618 84.9503 37.3451 84.9737L36.4001 81.5787C36.2601 81.602 36.1201 81.6137 35.9801 81.6137C35.8634 81.6137 35.7351 81.602 35.5951 81.5787L34.6501 84.9737C34.5334 84.9503 34.4167 84.9153 34.3001 84.8687L34.8951 82.6987L33.8101 84.6587C33.6934 84.612 33.5767 84.5537 33.4601 84.4837L35.2101 81.4037C34.9767 81.2637 34.7901 81.077 34.6501 80.8437L31.5701 82.5937C31.5001 82.477 31.4418 82.3603 31.3951 82.2437L33.3551 81.1587L31.1851 81.7537C31.1384 81.637 31.1034 81.5203 31.0801 81.4037L34.4751 80.4587C34.4517 80.3187 34.4401 80.1903 34.4401 80.0737C34.4401 79.9337 34.4517 79.7937 34.4751 79.6537L31.0801 78.7087C31.1034 78.592 31.1384 78.4753 31.1851 78.3587L33.3551 78.9537L31.3951 77.8687C31.4418 77.752 31.5001 77.6353 31.5701 77.5187L34.6501 79.2687C34.7901 79.0353 34.9767 78.8487 35.2101 78.7087L33.4601 75.6287C33.5767 75.5587 33.6934 75.5003 33.8101 75.4537L34.8951 77.4137L34.3001 75.2437C34.4167 75.197 34.5334 75.162 34.6501 75.1387L35.5951 78.5337C35.7351 78.5103 35.8634 78.4987 35.9801 78.4987C36.1201 78.4987 36.2601 78.5103 36.4001 78.5337L37.3451 75.1387C37.4618 75.162 37.5784 75.197 37.6951 75.2437L37.1001 77.4137L38.1851 75.4537C38.3017 75.5003 38.4184 75.5587 38.5351 75.6287L36.7851 78.7087C37.0184 78.8487 37.2051 79.0353 37.3451 79.2687L40.4251 77.5187C40.4951 77.6353 40.5534 77.752 40.6001 77.8687L38.6401 78.9537L40.8101 78.3587C40.8567 78.4753 40.8917 78.592 40.9151 78.7087L37.5201 79.6537C37.5434 79.7937 37.5551 79.9337 37.5551 80.0737C37.5551 80.1903 37.5434 80.3187 37.5201 80.4587L40.9151 81.4037C40.8917 81.5203 40.8567 81.637 40.8101 81.7537Z"
        fill="#E2E5E7"
      />
      <path
        d="M105.945 80.0736C105.945 84.4836 102.375 88.0536 97.9649 88.0536C93.5549 88.0536 89.9849 84.4836 89.9849 80.0736C89.9849 75.6636 93.5549 72.0586 97.9649 72.0586C102.375 72.0586 105.945 75.6636 105.945 80.0736Z"
        fill="#2E363D"
      />
      <path
        d="M103.075 80.0734C103.075 82.8734 100.765 85.1834 97.965 85.1834C95.13 85.1834 92.855 82.8734 92.855 80.0734C92.855 77.2384 95.13 74.9634 97.965 74.9634C100.765 74.9634 103.075 77.2384 103.075 80.0734Z"
        fill="#93A5A5"
      />
      <path
        d="M102.76 81.7537L100.59 81.1587L102.55 82.2437C102.503 82.3603 102.456 82.477 102.41 82.5937L99.2948 80.8437C99.1548 81.077 98.9681 81.2637 98.7348 81.4037L100.485 84.4837C100.368 84.5537 100.263 84.612 100.17 84.6587L99.0498 82.6987L99.6448 84.8687C99.5281 84.9153 99.4115 84.9503 99.2948 84.9737L98.3498 81.5787C98.2331 81.602 98.1048 81.6137 97.9648 81.6137C97.8248 81.6137 97.6965 81.602 97.5798 81.5787L96.5998 84.9737C96.4831 84.9503 96.3665 84.9153 96.2498 84.8687L96.8798 82.6987L95.7598 84.6587C95.6431 84.612 95.5381 84.5537 95.4448 84.4837L97.1598 81.4037C96.9264 81.2637 96.7398 81.077 96.5998 80.8437L93.5198 82.5937C93.4498 82.477 93.3915 82.3603 93.3448 82.2437L95.3398 81.1587L93.1348 81.7537C93.0881 81.637 93.0531 81.5203 93.0298 81.4037L96.4598 80.4587C96.4131 80.3187 96.3898 80.1903 96.3898 80.0737C96.3898 79.9337 96.4131 79.7937 96.4598 79.6537L93.0298 78.7087C93.0531 78.592 93.0881 78.4753 93.1348 78.3587L95.3398 78.9537L93.3448 77.8687C93.3915 77.752 93.4498 77.6353 93.5198 77.5187L96.5998 79.2687C96.7398 79.0353 96.9264 78.8487 97.1598 78.7087L95.4448 75.6287C95.5381 75.5587 95.6431 75.5003 95.7598 75.4537L96.8798 77.4137L96.2498 75.2437C96.3665 75.197 96.4831 75.162 96.5998 75.1387L97.5798 78.5337C97.6965 78.5103 97.8248 78.4987 97.9648 78.4987C98.1048 78.4987 98.2331 78.5103 98.3498 78.5337L99.2948 75.1387C99.4115 75.162 99.5281 75.197 99.6448 75.2437L99.0498 77.4137L100.17 75.4537C100.263 75.5003 100.368 75.5587 100.485 75.6287L98.7348 78.7087C98.9681 78.8487 99.1548 79.0353 99.2948 79.2687L102.41 77.5187C102.456 77.6353 102.503 77.752 102.55 77.8687L100.59 78.9537L102.76 78.3587C102.806 78.4753 102.841 78.592 102.865 78.7087L99.4698 79.6537C99.4931 79.7937 99.5164 79.9337 99.5398 80.0737C99.5164 80.1903 99.4931 80.3187 99.4698 80.4587L102.865 81.4037C102.841 81.5203 102.806 81.637 102.76 81.7537Z"
        fill="#E2E5E7"
      />
      <path
        d="M116.375 76.4338C115.955 79.1638 113.505 79.3038 113.505 79.3038L107.52 79.6888C107.31 74.5788 103.11 70.4838 97.9649 70.4838C92.6799 70.4838 88.3749 74.7538 88.3749 80.0738C88.3749 80.0971 88.3749 80.1205 88.3749 80.1438H45.57C45.57 80.1205 45.57 80.0971 45.57 80.0738C45.57 74.7538 41.2999 70.4838 35.9799 70.4838C30.6949 70.4838 26.425 74.7538 26.425 80.0738C26.425 80.0971 26.425 80.1205 26.425 80.1438H25.8299H23.4149C22.4699 80.1438 21.7 79.3388 21.7 78.3938V75.2088C21.7 74.4738 22.155 73.8438 22.82 73.5988C22.96 63.0988 29.4349 62.3988 29.4349 62.3988L47.32 59.9488L57.9249 53.6488C68.81 47.9088 105.21 50.4988 105.21 50.4988C107.765 50.7788 109.655 53.3688 109.655 53.3688L114.24 60.2288C115.5 63.0988 115.22 64.1138 115.22 64.1138V70.1338C118.09 71.4288 116.375 76.4338 116.375 76.4338Z"
        fill="#8D7C61"
      />
      <path
        d="M24.675 70.2386C24.675 70.2386 25.34 63.6586 33.88 64.3236C33.88 64.3236 30.275 68.6286 24.675 70.2386Z"
        fill="#4E4533"
      />
      <path
        d="M25.27 69.5737C25.27 69.5737 25.795 64.8837 32.27 65.3387C32.27 65.3387 29.54 68.3837 25.27 69.5737Z"
        fill="#FFDE17"
      />
      <path
        d="M21.7 78.3941V75.2091C21.7 75.0691 21.7116 74.9291 21.7349 74.7891H28C26.985 76.2941 26.425 78.1141 26.425 80.0741C26.425 80.0974 26.425 80.1207 26.425 80.1441H25.8299H23.4149C22.4699 80.1441 21.7 79.3391 21.7 78.3941Z"
        fill="#6A5E48"
      />
      <path
        d="M116.865 73.4238C116.865 74.9638 116.375 76.4338 116.375 76.4338C115.955 79.1638 113.505 79.3038 113.505 79.3038L107.52 79.6888C107.45 77.7988 106.82 76.0838 105.84 74.6138L113.855 74.3688C113.855 74.3688 115.815 74.5438 116.865 73.4238Z"
        fill="#6A5E48"
      />
      <path
        d="M45.5699 80.1441C45.5699 80.1207 45.5699 80.0974 45.5699 80.0741C45.5699 78.1141 45.0099 76.2941 43.9949 74.7891H89.9499C88.9699 76.2941 88.3749 78.1141 88.3749 80.0741C88.3749 80.0974 88.3749 80.1207 88.3749 80.1441H45.5699Z"
        fill="#6A5E48"
      />
      <path
        d="M107.625 70.0987C107.625 70.0987 109.27 66.4937 111.335 65.5137H115.22V70.0987H107.625Z"
        fill="url(#paint0_linear_51_5228)"
      />
      <path
        d="M24.3599 76.9238C24.3599 77.6588 23.7649 78.2538 23.0299 78.2538H21.7V75.5938H23.0299C23.7649 75.5938 24.3599 76.1888 24.3599 76.9238Z"
        fill="#2E363D"
      />
      <path
        d="M45.2899 75.3491C45.2899 75.3491 43.8199 69.364 35.2449 69.364C35.2449 69.364 43.5049 67.6491 46.0949 75.3491H45.2899Z"
        fill="#6A5E48"
      />
      <path
        d="M107.625 75.0686C107.625 75.0686 106.26 72.6886 105.105 71.9536C105.105 71.9536 106.33 71.9536 108.395 75.0686H107.625Z"
        fill="#6A5E48"
      />
      <path
        d="M47.3899 61.9438L109.935 60.5088V63.9388C109.935 63.9388 110.18 65.1988 107.87 68.2788L104.58 71.3238C104.58 71.3238 101.395 68.4538 95.6199 69.0488C95.6199 69.0488 90.8949 69.7488 88.7249 73.8788H46.6199C46.6199 73.8788 45.8849 69.6088 47.3899 61.9438Z"
        fill="url(#paint1_linear_51_5228)"
      />
      <path
        d="M52.605 66.3539L107.065 64.4639L52.605 67.2989C49.14 67.1939 48.405 70.5539 48.405 70.5539C48.615 66.1439 52.605 66.3539 52.605 66.3539Z"
        fill="url(#paint2_linear_51_5228)"
      />
      <path
        d="M68.0399 63.7988H64.4699C64.0499 63.7988 63.7349 63.4838 63.7349 63.0638C63.7349 62.6788 64.0499 62.3638 64.4699 62.3638H68.0399C68.4249 62.3638 68.7399 62.6788 68.7399 63.0638C68.7399 63.4838 68.4249 63.7988 68.0399 63.7988Z"
        fill="#8D7C61"
      />
      <path
        d="M68.7399 63.0986C68.7399 63.4836 68.4249 63.7986 68.0399 63.7986H64.4699C64.0849 63.7986 63.7699 63.4836 63.7349 63.0986H68.7399Z"
        fill="#4E4533"
      />
      <path
        d="M93.2401 62.9936H89.6351C89.2501 62.9936 88.9351 62.6786 88.9351 62.2936C88.9351 61.9086 89.2501 61.5586 89.6351 61.5586H93.2401C93.6251 61.5586 93.9401 61.9086 93.9401 62.2936C93.9401 62.6786 93.6251 62.9936 93.2401 62.9936Z"
        fill="#8D7C61"
      />
      <path
        d="M93.9401 62.3286C93.9401 62.7136 93.6251 62.9936 93.2401 62.9936H89.6351C89.2501 62.9936 88.9351 62.7136 88.9351 62.3286H93.9401Z"
        fill="#4E4533"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.565 78.779V78.744C44.905 70.029 47.53 60.859 47.565 60.789V60.754V60.719L58.3451 54.349C62.8251 51.969 72.835 50.709 87.29 50.709C96.81 50.709 104.79 51.269 105.14 51.304C105.175 51.304 109.445 51.584 110.075 59.214V63.939C110.075 65.584 107.975 68.174 107.87 68.279L104.58 71.569L104.545 71.499C101.92 69.119 97.8601 69.014 97.8251 69.014H97.7201C92.6801 69.014 90.055 71.639 88.7251 73.879C87.2551 76.259 87.01 78.674 87.01 78.709V78.779H47.565ZM47.6701 78.639H86.835C86.905 78.219 87.2201 75.979 88.55 73.774C89.9151 71.534 92.6101 68.874 97.7201 68.874H97.8251C97.8601 68.874 101.92 68.979 104.58 71.324L107.765 68.174C107.765 68.139 109.9 65.514 109.9 63.939V59.214C109.585 55.329 108.29 53.404 107.275 52.494C106.155 51.514 105.14 51.444 105.105 51.444C104.79 51.444 96.775 50.884 87.255 50.884C72.87 50.884 62.86 52.109 58.38 54.489L47.6701 60.859C47.4951 61.419 45.1501 70.239 47.6701 78.639Z"
        fill="#4E4533"
      />
      <path
        d="M70.0699 78.7087C69.1599 69.3637 71.6799 60.1237 71.6799 60.0537L71.8549 60.0887C71.8199 60.1587 69.3349 69.3637 70.2449 78.6737L70.0699 78.7087Z"
        fill="#4E4533"
      />
      <path
        d="M95.7599 69.049H95.6199C95.7249 64.009 96.7749 59.599 96.7749 59.564L96.9499 59.599C96.9149 59.634 95.8649 64.009 95.7599 69.049Z"
        fill="#4E4533"
      />
      <path
        d="M107.87 59.4938L48.79 60.7188L58.135 55.1888C62.545 52.8788 72.415 51.6538 86.66 51.6538C95.9 51.6538 103.775 52.2138 104.405 52.2488C105.84 52.4238 107.17 53.9288 107.555 54.4538C110.705 59.0038 107.87 59.4938 107.87 59.4938Z"
        fill="#0D1F27"
      />
      <path
        d="M61.6702 53.8588C65.2752 52.8088 70.6302 52.2138 77.3852 51.8638L85.5052 59.9838L68.1452 60.3338L61.6702 53.8588Z"
        fill="#223139"
      />
      <path
        d="M98.5251 51.8988L106.155 59.5288L101.29 59.6338L93.3801 51.7588C95.2701 51.7938 96.9851 51.8288 98.5251 51.8988Z"
        fill="#223139"
      />
      <path
        d="M72.5549 52.1787C73.4299 52.0737 74.2699 52.0388 75.2149 51.9688L73.7099 60.2287L69.9299 60.2988L72.5549 52.1787Z"
        fill="#6A5E48"
      />
      <path
        d="M97.9302 51.8638C98.4552 51.8988 99.1902 51.9338 99.8202 51.9688L98.1752 59.7038L95.4102 59.7738L97.9302 51.8638Z"
        fill="#6A5E48"
      />
      <path
        d="M55.6501 60.1939C55.6501 61.3839 54.0751 62.3639 52.1851 62.3639C50.2601 62.3639 49.9451 61.3839 49.9451 60.1939C49.9451 59.0039 50.2601 58.0239 52.1851 58.0239C54.0751 58.0239 55.6501 59.0039 55.6501 60.1939Z"
        fill="url(#paint3_radial_51_5228)"
      />
      <path
        d="M45.535 69.504C43.61 62.574 35.665 62.784 35.665 62.784L46.515 61.979C45.395 65.374 45.535 69.504 45.535 69.504Z"
        fill="url(#paint4_linear_51_5228)"
      />
      <path
        d="M115.22 70.0987H112.14C111.02 70.0987 110.145 69.2237 110.145 68.1037V68.0687C110.145 66.9487 111.02 66.0737 112.14 66.0737H115.22V70.0987Z"
        fill="#911012"
      />
      <path
        d="M33.985 69.364C30.205 69.749 27.195 73.809 27.195 73.809H24.885C28.56 69.084 33.985 69.364 33.985 69.364Z"
        fill="url(#paint5_linear_51_5228)"
      />
      <path
        d="M110.6 60.6138H113.715C113.715 60.6138 114.8 62.6088 114.66 64.2888V65.3038H110.355C110.355 65.3038 110.985 63.0638 110.6 60.6138Z"
        fill="url(#paint6_linear_51_5228)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_51_5228"
          x1="107.625"
          y1="65.5137"
          x2="115.22"
          y2="65.5137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A5E48" />
          <stop offset="1" stop-color="#8D7C61" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_51_5228"
          x1="79.5549"
          y1="63.3438"
          x2="79.5549"
          y2="69.1188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9F9079" />
          <stop offset="1" stop-color="#8D7C61" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_51_5228"
          x1="98.875"
          y1="67.2289"
          x2="45.78"
          y2="75.7339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A5E48" />
          <stop offset="1" stop-color="#8D7C61" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_51_5228"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(53.5851 59.2489) scale(3.535 3.535)"
        >
          <stop stop-color="#9F9079" />
          <stop offset="1" stop-color="#776951" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_51_5228"
          x1="39.27"
          y1="60.404"
          x2="46.06"
          y2="62.784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A5E48" />
          <stop offset="1" stop-color="#8D7C61" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_51_5228"
          x1="33.67"
          y1="71.709"
          x2="29.26"
          y2="74.824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9F9079" />
          <stop offset="1" stop-color="#8D7C61" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_51_5228"
          x1="114.94"
          y1="61.3838"
          x2="114.94"
          y2="64.7438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#97876F" />
          <stop offset="1" stop-color="#8D7C61" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SUV;
