import { ReactNode, useState } from "react";
import { FaAngleDown, FaAngleUp, FaBars } from "react-icons/fa";
import { Link, LinkProps, To, useLocation } from "react-router-dom";

interface MenuItemBasics {
  subitems?: MenuItem[];
  to: To;
  onClick?: () => void;
}

interface MenuItemWithText extends MenuItemBasics {
  text: string;
  component?: never;
}

interface MenuItemWithComponent extends MenuItemBasics {
  component: ReactNode;
  text?: never;
}

export type MenuItem = MenuItemWithText | MenuItemWithComponent;

interface MenuProps {
  items: MenuItem[];
  horizontal?: boolean;
}

const MenuLink = (
  props: LinkProps & React.RefAttributes<HTMLAnchorElement>
) => (
  <Link
    className={`flex items-center h-full text-bms-text-tertiary hover:text-bms-text-primary w-full ${props.className}`}
    {...props}
  />
);

export const Menu = ({ items, horizontal }: MenuProps) => {
  const ItemType = horizontal ? HorizontalMenuItem : VerticalMenuItem;

  const listItems = items.map((item, index) => (
    <ItemType key={index} {...item} />
  ));

  return (
    <nav>
      {horizontal ? (
        <ul className="flex space-x-6">{listItems}</ul>
      ) : (
        <ul>{listItems}</ul>
      )}
    </nav>
  );
};

const HorizontalMenuItem = (item: MenuItem) => {
  return (
    <li className="group py-3 relative">
      <MenuLink onClick={item.onClick} to={item.to}>
        {item.text ? item.text : item.component}
      </MenuLink>
      {item.subitems && (
        <div className="absolute hidden group-hover:block pt-4 right-0">
          <div className="bg-bms-fill-regular rounded-sm shadow-xl py-2 px-6 whitespace-nowrap min-w-[200px]">
            <ul>
              {item.subitems.map((subitem, index) => (
                <li key={index} className="py-3">
                  <MenuLink onClick={subitem.onClick} to={subitem.to}>
                    {subitem.text ? subitem.text : subitem.component}
                  </MenuLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </li>
  );
};

const VerticalMenuItem = (item: MenuItem) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <li className="py-3">
      <MenuLink
        to={item.to}
        onClick={() => {
          setExpanded((e) => !e);
          if (item.onClick) {
            item.onClick();
          }
        }}
      >
        {item.text ? item.text : item.component}{" "}
        {item.subitems && (
          <div className="ml-3">
            {expanded ? (
              <FaAngleUp className="inline" />
            ) : (
              <FaAngleDown className="inline" />
            )}
          </div>
        )}{" "}
      </MenuLink>
      {item.subitems && expanded && (
        <div className="my-4 pl-3 border-l-2 border-bms-text-tertiary">
          <ul>
            {item.subitems.map((subitem, index) => (
              <li key={index} className="py-2">
                <MenuLink onClick={subitem.onClick} to={subitem.to}>
                  {subitem.text ? subitem.text : subitem.component}
                </MenuLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};
