import { FaLocationArrow, FaMapPin } from "react-icons/fa";
import Textfield, { Props as TextfieldProps } from "../textfield/textfield";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Loader } from "@googlemaps/js-api-loader";

const API_KEY = "AIzaSyC4FQ8-kbWoEX4M9ktfXcwQgugLVCImvjI";

const loader = new Loader({
  apiKey: API_KEY,
  version: "weekly",
  libraries: ["places"],
});

export type Prediction = google.maps.places.AutocompletePrediction;

export interface Props {
  textfieldProps?: TextfieldProps;
  onChange?: (place: Prediction) => void;
}

const GeoSearch = ({ onChange = () => {}, textfieldProps }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.AutocompleteService>();

  useEffect(() => {
    loader.load().then((google) => {
      setAutocomplete(new google.maps.places.AutocompleteService());
    });
  }, []);

  const handleSelection = (p: Prediction) => {
    if (inputRef.current) {
      inputRef.current.value = p.description;
      inputRef.current.focus();
      inputRef.current.blur();
      onChange(p);
    }
  };

  const refreshResults = () => {
    const val = inputRef.current?.value;
    if (!val) return;
    if (!autocomplete) return;

    setIsLoading(true);

    console.log("REQUESTING " + val);
    autocomplete.getPlacePredictions(
      {
        input: val,
      },
      (
        predictions: Prediction[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        setIsLoading(false);
        console.log("Done");
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          setPredictions(predictions);
        } else {
          setPredictions([]);
        }
      }
    );
  };

  return (
    <div className="group relative mb-[5vh]">
      <Textfield
        {...textfieldProps}
        label="Location"
        ref={inputRef}
        onChange={refreshResults}
        autocomplete="off"
        stage = "listing"
      />
      {isLoading ? (
        <div className="absolute top-full bg-white z-10 w-full drop-shadow-xl rounded-md py-2 border">
          <p>Loading...</p>
        </div>
      ) : (
        <div
          className="hidden group-focus-within:block absolute top-full bg-white z-50 w-full drop-shadow-xl rounded-md py-2 border"
          style={{ zIndex: 5000 }}
        >
          <div>
            {predictions.map((p) => {
              return (
                <ListItem
                  key={p.place_id}
                  text={p.description}
                  onSelect={() => handleSelection(p)}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

interface ListItemProps {
  text: string;
  onSelect?: (address: string) => void;
}

const ListItem = ({ text, onSelect = () => {} }: ListItemProps) => {
  return (
    <label
      className="px-3 py-2.5 hover:bg-gray-100 cursor-pointer flex justify-start items-center space-x-4 relative focus-within:outline outline-1"
      tabIndex={-1}
    >
      <input
        type="radio"
        name="test"
        className="absolute opacity-0 width-0"
        onChange={(e) => e.target.checked && onSelect(text)}
      />
      <FaMapPin />
      <p>{text}</p>
    </label>
  );
};

export default GeoSearch;
