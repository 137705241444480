import { AuthContext } from "./AuthContext";
import { useContext, useEffect, useState } from "react";
import {
  ApolloClient,
  createHttpLink,
  ApolloProvider as OriginalApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

export type Props = {
  children?: React.ReactNode;
};

export const ApolloProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext);

  const httpLink = createHttpLink({
    uri: process.env["REACT_APP_GRAPHQL_ENDPOINT"],
  });

  const authLink = setContext(async (_, { headers }) => {
    if (auth.loggedIn) {
      const idToken = await auth.getIdToken();

      console.log("ID Token: " + idToken);

      if (idToken != null) {
        headers = {
          ...headers,
          Authorization: idToken,
        };
      }
    }

    return { headers };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Period: {
          merge: true,
        },
        Position: {
          merge: true,
        },
        sellerSettings: {
          merge: true,
        },
      },
    }),
  });

  if (!client) return <p>Loading</p>;

  return (
    <OriginalApolloProvider client={client}>{children}</OriginalApolloProvider>
  );
};
