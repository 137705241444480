import Button from "./button";
import { useDateText } from "../hooks/useDateText";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUrl } from "../hooks/useLoginUrl";
import DatePicker from "react-datepicker";
import { DatePickerForm } from "./datepickerform";
import { Invoice } from "./invoice";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";

export type Props = {
  start: Date | null;
  end: Date | null;
  onStartChange: (date: Date | null) => void;
  onEndChange: (date: Date | null) => void;
  spotId: string;
};

const GET_INVOICE =
  gql(`query PurchaseBarVerifyInvoice($spotId: String!, $start: String!, $end: String!) {
    getInvoice(spotId: $spotId, start: $start, end: $end) {
      token
      summary {
        price
        unit
      }
      payments {
        total
      }
    }
  }`);

const PurchaseBar = ({ start, end, onStartChange, onEndChange, spotId }: Props) => {

  const { loading, error, data } = useQuery(GET_INVOICE, {
    variables: {
      spotId: spotId,
      start: start?.toISOString() ?? '',
      end: end?.toISOString() ?? '',
    },
    skip: !start || !end
  })

  const summary = data?.getInvoice?.summary ? `$${data.getInvoice.summary.price.toFixed(2)} ${data.getInvoice.summary.unit}` : null
  const total = data?.getInvoice?.payments ? `$${data.getInvoice.payments.reduce((acc, cur) => { return acc + cur.total }, 0).toFixed(2)} total with fees` : null

  return (
    <div>
      <p className="font-semibold text-lg mb-3 hidden lg:block">
        Book a Reservation
      </p>

      <div className="hidden lg:block">
        <DatePickerForm id={spotId} start={start} end={end} onStartChange={onStartChange} onEndChange={onEndChange} />
      </div>

      { start && end && 
      <div className="hidden lg:block my-6">
        <Invoice spotId={spotId} start={start} end={end} />
      </div>
      }

      <div className="flex ">
        <div className="flex-1 lg:hidden">
          <p className="font-semibold text-2xl">{summary}</p>
          <p className="text-bms-text-secondary text-sm">{total}</p>
        </div>
        <Button
          className="w-full text-center"
          text="Reserve Spot"
          url={`/checkout/${spotId}?start=${start?.toISOString()}&end=${end?.toISOString()}`}
          disabled={data?.getInvoice?.token == null}
        />
      </div>
    </div>
  );
};

export default PurchaseBar;
