import { usePlacesWidget } from "react-google-autocomplete";
import { InputHTMLAttributes, forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

interface Place {
    lat: number;
    lng: number;
    name?: string;
}

export interface SearchQuery {
  location?: Place;
  dates?: {
    start: Date;
    end: Date;
  };
}

interface SearchProps {
  onQueryChange: (query: SearchQuery) => void;
}

export const Search = ({ onQueryChange }: SearchProps) => {

    const [selectedPlace, setSelectedPlace] = useState<Place>()

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>();

  const { ref, autocompleteRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: "AIzaSyCet1n2QjeuK6BssUHxjrfxUWGu659t7Rc",
    onPlaceSelected: (place) => {
        if(!place.geometry?.location) return;
        setSelectedPlace({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            name: place.formatted_address,
        })
    },
    options: { types: ["address"] },
  });

  useEffect(() => {
    let query = {}
    if(selectedPlace)
        query = { ...query, location: selectedPlace }
    if(dateRange?.[0] && dateRange?.[1])
        query = { ...query, dates: { start: dateRange[0], end: dateRange[1] }}
    onQueryChange(query)
  }, [onQueryChange, selectedPlace, dateRange])


  //console.log(autocompleteRef)

  return (
    <div className="flex p-2 px-3 rounded-md shadow-lg border-2 w-full bg-bms-fill-regular gap-3">
      {/* Google Maps API Key Not Working, Disabling Feature */}
      {/* <div className="flex-1">
        <SearchTextField placeholder={"Street, city, landmark"} ref={ref} />
      </div> */}
      <div className="flex-1">
        <DatePicker
          className="w-full"
          placeholderText="Dates"
          customInput={<SearchTextField />}
          selectsRange={true}
          startDate={dateRange?.[0]}
          endDate={dateRange?.[1]}
          onChange={(update) => {
            setDateRange(update);
          }}
          isClearable={true}
        />
      </div>
      {/*<button
      onClick={() =>
        onQueryChange({
          location: {
            lat: 42.5135,
            lng: -83.2941,
          },
          dates: { start: new Date("2023-06-14T00:00:00Z"), end: new Date("2023-06-15T23:59:59Z") },
        })
      }
    >
      Test
    </button>*/}
    </div>
  );
};

const SearchTextField = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      type="text"
      className="bg-transparent outline-none font-semibold py-2 w-full"
    />
  );
});
