import { AuthContext } from "../context/AuthContext";
import React, { useContext } from "react";
import {useForm, SubmitHandler} from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import { IoMdClose} from 'react-icons/io';
import {FcGoogle} from 'react-icons/fc'
import { AiFillApple } from "react-icons/ai";
import { useState } from "react";
import { TokenEndpointResponse } from "../helpers/CognitoConnector";
import {useRef, useEffect} from 'react'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Textfield from "./textfield/textfield";
import Popup from "./login/popup";
import PopupHeader from "./login/popupheader";
import Submitbutton from "./login/submitbutton";
import FocusTrap from "focus-trap-react"

export type Props = {
    email: string | UsernamePasswordOpts;
    password?: string;
    pageChanger : React.Dispatch<React.SetStateAction<string>>;
    setLoginShown : React.Dispatch<React.SetStateAction<boolean>>;
    setEmail : React.Dispatch<React.SetStateAction<string>>;
}

const LoginPop = ({ email, password, pageChanger, setLoginShown, setEmail }: Props) => {

    const auth = useContext(AuthContext);

    
    
    const {register, handleSubmit, reset, formState:{errors}} = useForm<Props>({defaultValues: {
        email : "", 
        password : ""
    }});

    const loginButtonRef = useRef<HTMLButtonElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [anerror, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [google, setGoogle] = useState(false);
    const [apple, setApple] = useState(false);

    const handleKeyDown = (event : React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            loginButtonRef.current.click();
        }
      };
    const onSubmit: SubmitHandler<Props> = async data => {
        setError(false);
        setLoading(true);
        try {
        const user = await Auth.signIn(data.email, data.password);
        // Access the expiration date
        const expirationDate = user.signInUserSession.accessToken.payload.exp;
        setLoginShown(false);
        auth.login({
            idToken: user.signInUserSession.idToken.jwtToken, 
            refreshToken: user.signInUserSession.refreshToken.token,
            expiration: new Date(expirationDate * 1000)
        });
        reset();
      } catch (error) {
        if (error.code === 'UserNotConfirmedException') {
            setLoginShown(false);
            setEmail(data.email.toString());
            try {
                await Auth.resendSignUp(data.email.toString());
                console.log('code resent successfully');
              } catch (err) {
                console.log('error resending code: ', err);
              }
            pageChanger("VerifyCreateAccount");
            handleLeave();

          } else {
            // Handle other login errors
            setError(true);
          }
      } finally {
        setLoading(false);
      }
    }

    const handleLeave = () => { //any time we go to another page we must remove errors and reset input fields
        setError(false);
        reset();
    }

    const hideLogin = () => {
        handleLeave();
        setLoginShown(false);
    }

    const forgot = () => {
        handleLeave();
        pageChanger("ForgotPassword");
    }   

    const showCreate = () => {
        handleLeave();
        pageChanger("CreateAccount");
    }

    
    const handleActivate = () => {
        if (emailInputRef.current) {
            console.log(true);
        emailInputRef.current.focus();
        }
    };

    useEffect(() => {
        const handleKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            hideLogin();
          }
        };
    
        window.addEventListener('keydown', handleKey);
    
        return () => {
          window.removeEventListener('keydown', handleKey);
        };
      }, [hideLogin]);

      const handleApple = () => {
        setApple(true);
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
        setApple(false); 
      }



    return (
        <div id = "element">
            <Popup height= "60">
                <FocusTrap active = {true} focusTrapOptions={{clickOutsideDeactivates : false, onActivate : handleActivate}}>
                    <div>
                        <PopupHeader boldtext="Log in to BuyMySpot" onClick = {hideLogin} />
                        <div style= {{visibility: anerror? "visible" : "hidden"}} className=" ml-4 sm:ml-12 mr-4 sm:mr-12 mt-[2vh] bg-red-200 text-white px-4 py-2 flex justify-between items-center">
                            <p role = "alert" className="font-poppins text-sm sm:text-1xl text-red-900">Incorrect username or password.</p>
                            <button className="bg-transparent border-none p-0 cursor-pointer" onClick={() => setError(false)}>
                                <IoMdClose className= "text-gray-500 right-4 w-4 sm:w-6 h-4 sm:h-6 cursor-pointer"></IoMdClose>
                            </button>
                        </div>
                        <div style = {{marginTop : anerror? "-4vh" : "-8vh"}}></div>
                        <form noValidate = {true} onSubmit={handleSubmit(onSubmit)}>
                            <Textfield label = "Email Address" 
                            placeholder="Email Address" 
                            {...register("email", { required:  true})}
                            type="email" 
                            id="email"/>
                            <Textfield label = "Password" 
                            placeholder="Password" 
                            {...register("password", { required:  true})}
                            type="password" 
                            onKeyDown={handleKeyDown} 
                    
                            id="password"/>
                            <div className="text-right  mb-[-2vh] sm:mb-[0vh]">
                                <button onClick = {forgot}
                                className="text-gray-400 text-opacity-61 text-sm  mr-4 sm:mr-12 font-poppins hover:underline">Forgot password?</button>
                            </div>
                            <Submitbutton text = "Log In" ref = {loginButtonRef} loading = {loading} />
                            
                        </form>

                        <div className="flex items-center mx-[8%] mt-[2vh]">
                            <div className="flex-grow h-px bg-gray-300"></div>
                            <span className="mx-2 text-gray-300 font-poppins text-sm">OR</span>
                            <div className="flex-grow h-px bg-gray-300"></div>
                        </div>
                        <div className = "mt-[-2vh]"></div>
                        {/* <Submitbutton text = "Continue with Apple" 
                        onClick={handleApple}
                        loading = {apple}
                    
                        use = "Apple"/>
                    
                        <div className = "mt-[-4vh]"></div>
                        <Submitbutton text = "Continue with Google" 
                        
                        onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
                        use = "Google"/> */}
                        
                        <p className="text-center mt-[5vh] sm:mt-[2vh]">
                            <span className="text-gray-400">Don't have an account? </span>
                            <button  onClick = {showCreate} className="text-blue-500 underline font-semibold">Sign Up</button>
                        </p>
                    </div>
                </FocusTrap>
            </Popup>
        </div>
    )
}


export default LoginPop;