import React, { useEffect, useState } from "react";
import { Stripe, loadStripe } from "@stripe/stripe-js";

interface StripeData {
  stripePromise: Promise<Stripe | null> | null;
  apiKey: string | null;
  registerApiKey: (key: string) => void
}

const defaultValue: StripeData = {
  stripePromise: null,
  apiKey: null,
  registerApiKey: () => {}
};

export const StripeContext = React.createContext(defaultValue);

export type Props = {
  children?: React.ReactNode;
  stripePromise?: Promise<Stripe | null> | null;
};

export const StripeContextProvider = ({ children }: Props) => {

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
  const [apiKey, setApiKey] = useState<string | null>(null);

  const registerApiKey = (newKey: string) => {
    if(newKey !== apiKey) {
      setApiKey(newKey)
      return
    }
    console.log("Stripe instance already exists")
  }

  useEffect(() => {
    if(!apiKey) return
    setStripePromise(loadStripe(apiKey))
  }, [apiKey])

  return (
    <StripeContext.Provider
      value={{
        stripePromise: stripePromise,
        apiKey: apiKey,
        registerApiKey: registerApiKey,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};
