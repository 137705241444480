import { gql } from "../__generated__";
import { useLazyQuery, useQuery } from "@apollo/client";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";

interface DatePickerFormProps {
  id: string;
  start: Date | null;
  end: Date | null;
  onStartChange: (d: Date | null) => void;
  onEndChange: (d: Date | null) => void;
}

const GET_SPOT = gql(`query GetSpotDatePickerQuery($spotId: ID!) {
    spot(id: $spotId) {
      id
      period {
        start
        end
      }
      minimumLeaseRange
    }
  }`);

const GET_INVOICE =
  gql(`query DatepickerVerifyInvoice($spotId: String!, $start: String!, $end: String!) {
    getInvoice(spotId: $spotId, start: $start, end: $end) {
      token
    }
  }`);

export const DatePickerForm = ({
  id,
  start,
  end,
  onStartChange,
  onEndChange,
}: DatePickerFormProps) => {
  const [errorMsg, setErrorMsg] = useState<string>();

  const { loading, error, data } = useQuery(GET_SPOT, {
    variables: {
      spotId: id,
    },
  });

  const [verifyInvoice, { data: verificationResult }] =
    useLazyQuery(GET_INVOICE);

  useEffect(() => {
    if (!start || !end) return;

    if (start > end) {
      setErrorMsg("Exit by date must be later than the Enter on date");
      return;
    }

    verifyInvoice({
      variables: {
        spotId: id,
        start: start.toISOString(),
        end: end.toISOString(),
      },
    });

    setErrorMsg(undefined);
  }, [start, end]);

  useEffect(() => {
    if(verificationResult != null && verificationResult.getInvoice == null) setErrorMsg("This spot is not available for the dates you have selected")
  }, [verificationResult])

  if (loading || !data) return null;

  const minDate = data.spot?.period?.start
    ? new Date(data.spot?.period?.start)
    : null;
  const maxDate = data.spot?.period?.end
    ? new Date(data.spot?.period?.end)
    : null;

  return (
    <div>
      <div
        className={`flex border-2 rounded-lg border-bms-border-medium ${
          errorMsg && "border-bms-error-red"
        }`}
      >
        <div className="px-2 py-1 flex-1">
          <p className="text-bms-text-secondary">Enter on</p>
          <DatePicker
            selected={start}
            onChange={onStartChange}
            className="outline-none w-full"
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <div className="px-2 py-1 flex-1 border-l-2 border-bms-border-medium">
          <p className="text-bms-text-secondary">Exit by</p>
          <DatePicker
            selected={end}
            onChange={onEndChange}
            className="outline-none w-full"
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      </div>
      {errorMsg && <p className="text-bms-error-red mt-2">{errorMsg}</p>}
    </div>
  );
};
