import { useDateText } from "../hooks/useDateText";
import { Spot } from "../queries/getAvailableSpotsQuery";
import { getSpotType } from "../queries/getSpotQuery";

export interface Props {
  spot: Spot;
  start?: string | null;
  end?: string | null;
  price?: string
}

const SpotCard = ({ spot, start, end, price}: Props) => {
  const dateInfo = useDateText(
    spot?.period.start ?? "",
    spot?.period.end ?? ""
  );

  return (
    <div className="p-2 py-4 flex h-40">
      <div className="flex flex-1 flex-col mr-2 py-1 text-bms-text-secondary">
        <h1 className="text-xl font-bold text-bms-text-primary">
          { spot.name }
        </h1>
        <span className="mt-2">{getSpotType(spot.spotType ?? -1)}</span>
        <div className="flex-1" />
       {/* <div className="flex mt-2">
          <span className="mr-2">🚗 Valet</span>
          <span>🅿️ Covered</span>
  </div> */}
      </div>
      <div className="relative shrink-0 h-full">
        <img
          src={spot.images?.[0]?.href ?? "/default.png"}
          alt="Parking Spot"
          className="aspect-square rounded-lg shadow-lg h-full"
        />
        { price && <span className="absolute bottom-0 right-0 m-2 bg-bms-fill-regular p-1 px-2 shadow-md rounded-md text-xl font-bold">
          {price}
        </span> }
      </div>
    </div>
  );

  return (
    <a
      href={`/spot/${spot.id}?s=1${start && `&start=${start}`}${
        end && `&end=${end}`
      }`}
    >
      <div className="max-w-md bg-slate-50 drop-shadow h-32 overflow-hidden">
        <div className="flex">
          <div className="basis-1/3 h-32 mr-5">
            <img
              src={spot.images?.[0]?.href ?? "/default.png"}
              alt="Spot"
              className="h-32 w-full object-cover"
            />
          </div>
          <div className="flex flex-col justify-center">
            <p className="font-bold text-md">{spot.name}</p>
            <p className="text-sm">
              {dateInfo.singular ? "Available on" : "Available from"}{" "}
              {dateInfo.short}
            </p>
            <p className="text-sm">
              {spot.pricing.daily && `$${spot.pricing.daily}/day     `}
              {spot.pricing.weekly && `$${spot.pricing.weekly}/wk     `}
              {spot.pricing.monthly && `$${spot.pricing.monthly}/mo     `}
              {spot.pricing.lease && `$${spot.pricing.lease}/lease`}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default SpotCard;
