import LoginPop from "./loginpop";
import AlreadyLoginPop from "./alreadyloginpop";
import ForgotPassword from "./forgotpassword";
import CreateAccount from "./createaccount";
import VerifyCreateAccount from "./verifycreateaccount";
import VerifyChangePassword from "./verifychangepassword";
import ChangePassword from "./changepassword";
import SuccesfullyChanged from "./sucesfullychanged";
import SuccessfullyCreated from "./succesfullycreated";
import { AuthContext } from "../context/AuthContext";
import React, { useContext } from "react";
import { useState } from "react";

export type Props = {
    setLoginShown : React.Dispatch<React.SetStateAction<boolean>>;

}

const AllLogin =  ({ setLoginShown } : Props) => {

  const [currentPage, setCurrentPage] = useState<string>('Login');
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>("");

  return (
    <>
      <div>
        {currentPage === "Login" && <LoginPop setLoginShown={setLoginShown} pageChanger = {setCurrentPage} setEmail={setEmail} email="" />}
        {currentPage === "ForgotPassword" &&  <ForgotPassword setLoginShown={setLoginShown} pageChanger = {setCurrentPage} setEmail={setEmail} email="" /> }
        {currentPage === "VerifyChangePassword" && <VerifyChangePassword  setLoginShown={setLoginShown} pageChanger = {setCurrentPage} setCode = {setCode} mail = {email} page = {currentPage} email="" /> }
        {currentPage === "ChangePassword" && <ChangePassword  setLoginShown={setLoginShown} pageChanger = {setCurrentPage}  mail = {email} code = {code} password="" confirmpassword="" /> }
        {currentPage === "SuccessfullyChanged" && <SuccesfullyChanged  setLoginShown={setLoginShown} pageChanger = {setCurrentPage}  email="" password="" />}
        {currentPage === "SuccessfullyCreated" && <SuccessfullyCreated setLoginShown={setLoginShown} pageChanger = {setCurrentPage}   email="" password="" />}
        {currentPage === "CreateAccount" && <CreateAccount  setLoginShown={setLoginShown} pageChanger = {setCurrentPage} setEmail={setEmail} email="" password="" firstName="" lastName="" />}
        {currentPage === "VerifyCreateAccount" && <VerifyCreateAccount setLoginShown={setLoginShown} pageChanger = {setCurrentPage} setEmail={setEmail}  mail = {email } page = {currentPage} email="" />}
      </div>
    </>
  );
};

export default AllLogin;
