import React from "react";
import { To, Link } from "react-router-dom";

export type Props = {
  url?: To;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  onKeyDown? : React.KeyboardEventHandler<HTMLAnchorElement>;
  text: string;
  type?: "primary" | "primary-white" | "tertiary" | "tertiary-white";
  disabled?: boolean;
  loading?: boolean;
  className?: string | undefined;
  tabIndex?: number | undefined;
};

const Button = ({
  url,
  onClick,
  onKeyDown,
  text,
  type = "primary",
  loading,
  disabled,
  className,
  tabIndex,
}: Props) => {
  return (
    <Link
      to={url ?? ""}
      onClick={disabled ? (event) => event.preventDefault() : onClick}
      onKeyDown={disabled ? (event) => event.preventDefault() : onKeyDown}
      tabIndex={tabIndex}
    >
      <div
        className={`
            ${ type === "primary" && "bg-bmsblue text-slate-50" }
            ${ type === "primary-white" && "bg-slate-50 text-bmsblue" } 
            ${ type === "tertiary" && "bg-transparent text-bmsblue" }
            ${ type === "tertiary-white" && "bg-transparent text-slate-50"}
            ${ disabled && "opacity-50" }
            px-5 py-3 rounded-md text-sm font-semibold table cursor-pointer ${className}
        `}
      >
        {loading ? "Loading..." : text}
      </div>
    </Link>
  );
};

export default Button;
