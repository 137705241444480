import Button from "./button";
import { AuthContext } from "../context/AuthContext";
import { useContext, useState, ReactNode, useEffect } from "react";
import { useLoginUrl } from "../hooks/useLoginUrl";
import { Auth } from "aws-amplify";
import AllLogin from "./alllogin";
import TopBar from "./topbar";
import { Menu } from "./menu";
import { FaBars } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6"
import { useLocation } from "react-router-dom";

const Header = () => {
  const auth = useContext(AuthContext);
  const loginUrl = useLoginUrl();

  const [loginShown, setLoginShown] = useState(false);
  const logout = async () => {
    try {
      await Auth.signOut();
      auth.logout();
    } catch (error) {
      console.log("error signing out: ", error);
      //determine error later
    }
  };

  const handleLoginButtonClick = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // If there is an authenticated user session, show the login popup
      const userEmail = user.signInUserSession.idToken.payload.email;
      auth.setEmail(userEmail);
      auth.setScrollPosition(window.scrollY);
      auth.showAlreadyLoginPopup();
    } catch (error) {
      // If there is no authenticated user session, navigate to the login page
      // or perform any other desired action
      // ...
      auth.showLoginPopup();
    }
  };

  const location = useLocation()

  const [verticalMenuExpanded, setVerticalMenuExpanded] = useState(false);

 useEffect(() => {
  setVerticalMenuExpanded(false)
 }, [location])

  const menuItems = auth.loggedIn ? [
    {
      text: "Sell Parking",
      to: { pathname: "/sell" },
    },
    {
      text: "Messages",
      to: { pathname: "/messages" },
    },
    {
      text: "Orders",
      to: { pathname: "/orders" },
    },
    {
      text: `Hi, ${auth.name}`,
      to: {},
      subitems: [
        {
          text: "Logout",
          to: {},
          onClick: logout,
        }
      ],
    },
    // Commented Out Mobile App Link
    // {
    //   component: (
    //     <Button
    //       url="https://onelink.to/gjr88j"
    //       text="Download Our App"
    //       type="primary"
    //     />
    //   ),
    //   to: {},
    // },
  ] : [
    {
      text: "Login",
      to: {},
      onClick: () => setLoginShown(true),
    },
    // Commented Out Mobile App Link
    // {
    //   component: (
    //     <Button
    //       url="https://onelink.to/gjr88j"
    //       text="Download Our App"
    //       type="primary"
    //     />
    //   ),
    //   to: {},
    // },
  ];

  return (
    <div>
      {/* Commented out TopBar Component - Old Zendesk Phone # */}
      {/* <TopBar /> */}
      <div className="bg-white lg:sticky lg:top-0 lg:z-50 drop-shadow-md">
        <div
          style={{ visibility: loginShown ? "visible" : "hidden" }}
          className="absolute inset-0 h-[100%] bg-gray-500 opacity-50 pointer-events-none"
        ></div>
        <div className="container mx-auto">
          <div className="flex items-center h-16 px-4">
            <div className="flex-1 pr-8">
              <a href="/">
                <img
                  src="/logo-black.png"
                  alt="BuyMySpot Logo"
                  className="w-60"
                />
              </a>
            </div>

            <div className="md:hidden">
              <FaBars size={28} onClick={() => setVerticalMenuExpanded((e) => !e)} />
            </div>

            <div className="hidden md:block">
              <Menu horizontal={true} items={menuItems} />
            </div>
          </div>
        </div>
      </div>
      <div>{loginShown && <AllLogin setLoginShown={setLoginShown} />}</div>
      {verticalMenuExpanded && (
        <div className="md:hidden fixed right-0 top-0 bottom-0 left-0 bg-black/30 px-4 shadow-lg z-[1000000]">
          <div className="absolute top-0 right-0 bottom-0 min-w-[275px] bg-bms-fill-regular px-4 py-6">
            <div className="pb-3 mb-2">
              <FaXmark onClick={() => setVerticalMenuExpanded(e => !e)} size={28} />
            </div>
            <Menu horizontal={false} items={menuItems} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
