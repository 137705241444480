import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import Textfield from "../../components/textfield/textfield";
import { FaSearch, FaTimes } from "react-icons/fa";
import ImageRadio, {
  ImageRadioGroup,
} from "../../components/image-radio/image-radio";
import { Apartment, Garage, House, Lot } from "../../svg";
import { RequirementsForm } from "./step4-requirements";
import { Details } from "./step3-details";
import GeoSearch, { Prediction } from "../../components/geosearch/geosearch";
import CoordinatePicker from "../../components/coordinate-picker/coordinate-picker";
import FormActions from "../../components/formactions";
import { useNavigate } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { useState } from "react";
import { ControllerRenderProps } from "react-hook-form";

interface CreatePropertyMutationData {
  address: string;
  name: string;
  position: {
    lat: number;
    lng: number;
  };
  propertyType: number;
}

interface CreatePropertyMutationResponse {
  code: number;
  success: boolean;
  property: {
    id: string;
  };
}

export const CreateListingForm = () => {
  const { register, handleSubmit, control } = useForm();

  const navigate = useNavigate();

  const [createProperty, { loading, error, data }] = useMutation<
    { createProperty: CreatePropertyMutationResponse },
    { data: CreatePropertyMutationData }
  >(gql`
    mutation CreateProperty($data: PropertyData!) {
      createProperty(data: $data) {
        code
        property {
          id
        }
        success
      }
    }
  `);

  const onSubmit = handleSubmit((data) => {
    // alert("test");

    console.log(data);

    createProperty({
      variables: {
        data: {
          address: data.location.address,
          position: data.location.position,
          propertyType: Number(data.propertyType),
          name: data.location.name,
        },
      },
      onCompleted(data) {
        navigate(`../listings/${data.createProperty.property.id}/new`);
      },
    });

    /*createProperty({
        variables: {
            data: {

            }
        }
    })*/

    //return navigate("../pricing");
  });

  return (
    <div className={"bg-white shadow-md p-4 rounded-lg max-w-xl m-auto"}>
      <form onSubmit={onSubmit}>
        <Controller
          control={control}
          name={"location"}
          rules={{ required: true }}
          render={(props) => <LocationPicker {...props} />}
        />

        <ImageRadioGroup>
          <ImageRadio
            {...register("propertyType", { required: true })}
            text={"House"}
            value={0}
            icon={<House />}
          />
          <ImageRadio
            {...register("propertyType", { required: true })}
            text={"Apartment"}
            value={1}
            icon={<Apartment />}
          />
          <ImageRadio
            {...register("propertyType", { required: true })}
            text={"Lot"}
            value={2}
            icon={<Lot />}
          />
          <ImageRadio
            {...register("propertyType", { required: true })}
            text={"Garage"}
            value={3}
            icon={<Garage />}
          />
        </ImageRadioGroup>
        <FormActions
          onClick={onSubmit}
          submitText="Continue"
          submitLoading={loading}
          submitDisabled={loading}
        />
      </form>
    </div>
  );
};

const LocationPicker = ({
  field: { onChange },
}: {
  field: ControllerRenderProps<FieldValues, "location">;
}) => {
  const [prediction, setPrediction] = useState<Prediction>();

  return (
    <>
      <div className="text-center text-2xl font-bold mt-[1vh] mb-[-3vh]">
        Create Listing
      </div>
      <GeoSearch
        textfieldProps={{
          label: "Location",
          leftAccessory: <FaSearch />,
          rightAccessory: <FaTimes />,
        }}
        onChange={setPrediction}
      />
      {prediction != null && (
        <CoordinatePicker
          placeId={prediction?.place_id}
          onChange={(position) =>
            onChange({
              position,
              address: prediction.description,
              name: prediction.structured_formatting.main_text,
            })
          }
        />
      )}
    </>
  );
};
