const Apartment = () => {
  return (
    <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M116.177 102.081C116.177 103.852 114.732 105.296 112.961 105.296C111.936 105.296 111.051 104.83 110.492 104.131C109.187 104.784 107.743 105.156 106.205 105.11C103.968 105.063 101.965 104.131 100.427 102.733C98.9361 103.619 97.2121 103.758 95.6744 103.246C94.8357 106.228 92.1331 108.418 88.8714 108.418C85.2835 108.418 82.2548 105.669 81.8354 102.128C80.9967 102.034 80.158 101.662 79.5057 100.963C78.2942 99.658 78.1544 97.7476 79.0863 96.3498C78.962 95.6974 78.8999 95.0295 78.8999 94.3461C79.0397 89.8263 82.3946 86.1453 86.6348 85.5395C86.6348 85.5395 86.6348 85.524 86.6348 85.4929C85.5631 83.9553 84.9108 81.9982 85.004 79.948C85.1437 74.8691 89.2442 70.8618 94.1834 71.0016C98.8429 71.1414 102.571 74.9157 102.897 79.5752C103.875 79.2025 104.994 79.0161 106.112 79.0627C110.958 79.2025 114.732 83.1631 114.825 88.0557C116.829 89.3138 118.227 91.5504 118.227 94.1132C118.227 96.4895 117.062 98.5863 115.291 99.891C115.85 100.45 116.177 101.242 116.177 102.081Z"
        fill="#8AA451"
      />
      <path
        d="M109.979 101.662C109.979 101.662 105.133 108.558 99.4021 111.4C99.5419 112.379 99.5885 117.411 99.6351 118.856C99.6351 119.555 99.8215 120.207 99.9147 120.859H95.3017C95.5812 119.508 95.9074 117.737 96.0938 116.2C96.187 115.454 96.2802 111.633 96.4666 109.303C92.5059 106.554 87.0542 99.4251 87.0542 99.4251C87.9861 100.59 91.8536 103.526 92.6457 104.457C93.0185 104.178 92.2263 101.569 92.1797 99.5649C92.1797 99.5649 93.9504 103.246 93.3913 105.11C94.23 105.809 95.6278 106.834 96.6063 107.346C96.7927 104.318 96.9791 102.221 97.0723 100.776C97.1189 100.45 94.0902 94.02 94.23 94.253C95.4881 96.1168 96.0472 96.443 97.1189 98.633C97.4451 99.3319 98.2372 93.0415 98.2372 93.0415C98.7032 93.4143 98.1906 97.7477 98.377 98.7262C98.4702 99.332 98.5168 100.078 98.5634 100.87C98.61 101.708 98.7032 103.852 98.7964 104.737C99.5885 104.504 101.126 102.407 103.037 98.2602C103.037 98.2602 101.592 105.296 99.0293 106.182C99.1225 107.346 99.1226 108.045 99.076 108.931C100.8 108.791 105.832 105.716 109.979 101.662Z"
        fill="#493B34"
      />
      <path
        opacity="0.5"
        d="M91.5274 86.751C94.1368 87.5431 94.7425 87.7761 94.1368 88.1955C92.4127 89.3604 91.7604 89.4536 88.5919 88.7546C83.3731 87.5431 79.7386 93.9734 78.9465 95.6974C78.9155 95.2625 78.8999 94.8121 78.8999 94.3461C79.0397 89.8263 82.3946 86.1453 86.6348 85.5395C86.6348 85.5395 86.6348 85.524 86.6348 85.4929C85.5631 83.9553 84.9108 81.9982 85.004 79.948C85.1438 74.8691 89.2442 70.8618 94.1834 71.0016C97.1655 71.0948 99.7283 72.6325 101.266 74.9157C98.6099 73.611 96.9325 72.7722 92.8321 73.7041C85.004 75.5214 85.8427 84.9338 91.5274 86.751Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M102.897 79.5753C103.875 79.2025 104.994 79.0161 106.112 79.0627C108.535 79.1093 110.678 80.1344 112.216 81.7653C111.61 81.7653 109.42 81.2527 108.535 81.1595C106.019 80.9731 103.223 81.6255 101.732 81.9983C99.2157 82.6972 98.144 82.604 99.0759 81.2993C100.054 79.9946 102.897 79.5753 102.897 79.5753Z"
        fill="#E5E884"
      />
      <g opacity="0.5">
        <path
          d="M115.291 99.8911C115.85 100.45 116.176 101.242 116.176 102.081C116.176 103.852 114.732 105.296 112.961 105.296C111.936 105.296 111.051 104.83 110.492 104.131C109.187 104.784 107.743 105.156 106.205 105.11C103.968 105.063 101.965 104.131 100.427 102.733C98.936 103.619 97.2119 103.759 95.6742 103.246C94.8355 106.228 92.133 108.418 88.8713 108.418C85.2834 108.418 82.2547 105.669 81.8353 102.128C80.9966 102.035 80.1579 101.662 79.5055 100.963C79.3191 100.745 79.1638 100.512 79.0396 100.264C79.4434 100.264 79.9404 100.264 80.5306 100.264C82.208 100.217 82.6274 100.031 82.6274 100.031C82.6274 100.031 85.6096 102.966 88.5917 102.174C94.7889 100.497 94.2298 94.253 94.2298 94.253C94.2298 94.253 97.1187 98.6331 100.567 99.4718C103.456 100.124 106.857 99.8445 109.14 98.4933C114.592 95.185 111.284 89.5003 111.284 89.5003C111.284 89.5003 109.746 88.1956 112.216 87.45C113.287 87.1239 113.986 86.3783 114.452 85.6328C114.685 86.4249 114.825 87.2171 114.825 88.0558C116.829 89.3139 118.227 91.5505 118.227 94.1133C118.227 96.4897 117.062 98.5864 115.291 99.8911Z"
          fill="#385A27"
        />
        <path
          d="M110.445 95.1849C110.445 96.2566 109.606 97.1419 108.535 97.1419C107.463 97.1419 106.578 96.2566 106.578 95.1849C106.578 94.1132 107.463 93.2744 108.535 93.2744C109.606 93.2744 110.445 94.1132 110.445 95.1849Z"
          fill="#385A27"
        />
        <path
          d="M106.531 97.1419C106.531 97.7942 105.972 98.3534 105.273 98.3534C104.621 98.3534 104.062 97.7942 104.062 97.1419C104.062 96.4429 104.621 95.8838 105.273 95.8838C105.972 95.8838 106.531 96.4429 106.531 97.1419Z"
          fill="#385A27"
        />
        <path
          d="M87.054 99.1924C87.054 99.8914 86.4949 100.404 85.8426 100.404C85.1436 100.404 84.5845 99.8914 84.5845 99.1924C84.5845 98.4935 85.1436 97.981 85.8426 97.981C86.4949 97.981 87.054 98.4935 87.054 99.1924Z"
          fill="#385A27"
        />
        <path
          d="M109.746 92.0631C109.746 92.5756 109.327 92.995 108.861 92.995C108.348 92.995 107.929 92.5756 107.929 92.0631C107.929 91.5505 108.348 91.1777 108.861 91.1777C109.327 91.1777 109.746 91.5505 109.746 92.0631Z"
          fill="#385A27"
        />
        <path
          d="M88.8712 97.002C88.8712 97.5146 88.4518 97.9339 87.9859 97.9339C87.4733 97.9339 87.054 97.5146 87.054 97.002C87.054 96.536 87.4733 96.1167 87.9859 96.1167C88.4518 96.1167 88.8712 96.536 88.8712 97.002Z"
          fill="#385A27"
        />
      </g>
      <path
        d="M113.427 101.289C113.427 101.941 112.915 102.5 112.216 102.5C111.517 102.5 111.004 101.941 111.004 101.289C111.004 100.59 111.517 100.031 112.216 100.031C112.915 100.031 113.427 100.59 113.427 101.289Z"
        fill="#87AD54"
      />
      <path
        d="M114.592 99.2853C114.592 99.7513 114.22 100.077 113.8 100.077C113.334 100.077 113.008 99.7513 113.008 99.2853C113.008 98.8659 113.334 98.4932 113.8 98.4932C114.22 98.4932 114.592 98.8659 114.592 99.2853Z"
        fill="#87AD54"
      />
      <path
        opacity="0.5"
        d="M91.9002 77.8048C91.9002 78.5037 91.3411 79.0628 90.6888 79.0628C89.9898 79.0628 89.4307 78.5037 89.4307 77.8048C89.4307 77.1524 89.9898 76.5933 90.6888 76.5933C91.3411 76.5933 91.9002 77.1524 91.9002 77.8048Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M88.6383 91.1774C88.6383 92.2957 87.7064 93.1811 86.5881 93.1811C85.5164 93.1811 84.5845 92.2957 84.5845 91.1774C84.5845 90.0591 85.5164 89.1738 86.5881 89.1738C87.7064 89.1738 88.6383 90.0591 88.6383 91.1774Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M92.5523 75.5679C92.5523 75.8941 92.3194 76.1737 91.9932 76.1737C91.667 76.1737 91.3875 75.8941 91.3875 75.5679C91.3875 75.2417 91.667 75.0088 91.9932 75.0088C92.3194 75.0088 92.5523 75.2417 92.5523 75.5679Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M98.9361 83.3497C98.9361 83.6759 98.6566 83.9088 98.3304 83.9088C98.0508 83.9088 97.7712 83.6759 97.7712 83.3497C97.7712 83.0235 98.0508 82.7905 98.3304 82.7905C98.6566 82.7905 98.9361 83.0235 98.9361 83.3497Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M96.8858 117.597L96.28 120.859H95.3015C95.5811 119.508 95.9073 117.737 96.0936 116.199C96.1868 115.454 96.28 111.819 96.4198 109.49L96.8858 109.396V110.235V117.597Z"
        fill="#A86F41"
      />
      <path
        d="M93.5774 120.859H24.7087V36.0083H93.5774V120.859Z"
        fill="#F2F0D1"
      />
      <path
        d="M96.513 36.0085H21.7732V24.8721H96.513V36.0085Z"
        fill="#F2F0D1"
      />
      <path
        d="M96.513 36.0083H21.7732V30.4634H96.513V36.0083Z"
        fill="#D6D5BB"
      />
      <path
        d="M55.1358 24.7321H45.77V19.1406H55.1358V24.7321Z"
        fill="#D6D5BB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.9741 42.8579H41.1105V59.9586H29.9741V42.8579ZM45.7235 42.8579H56.8133V59.9586H45.7235V42.8579ZM61.4263 42.8579H72.5627V59.9586H61.4263V42.8579ZM77.1291 42.8579H88.2655V59.9586H77.1291V42.8579ZM29.9741 66.5286H41.1105V83.6293H29.9741V66.5286ZM45.7235 66.5286H56.8133V83.6293H45.7235V66.5286ZM61.4263 66.5286H72.5627V83.6293H61.4263V66.5286ZM77.1291 66.5286H88.2655V83.6293H77.1291V66.5286ZM29.9741 90.2459H41.1105V107.347H29.9741V90.2459ZM45.7235 90.2459H56.8133V107.347H45.7235V90.2459ZM61.4263 90.2459H72.5627V98.8661H61.4263V90.2459ZM77.1291 90.2459H88.2655V107.347H77.1291V90.2459Z"
        fill="url(#paint0_linear_56_2250)"
      />
      <path
        d="M72.5627 120.86H61.4263V102.547H72.5627V120.86Z"
        fill="#716558"
      />
      <path
        d="M96.513 120.859H21.7732V117.365H96.513V120.859Z"
        fill="#A6A8AB"
      />
      <path
        opacity="0.1"
        d="M93.5774 120.859H24.7087L93.5774 36.0083V120.859Z"
        fill="#020051"
      />
      <defs>
        <linearGradient
          id="paint0_linear_56_2250"
          x1="62.4048"
          y1="10.4738"
          x2="123.772"
          y2="71.8405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89C5F2" />
          <stop offset="1" stop-color="#002F64" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Apartment;
