const Compact = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117.547 83.9659C117.547 85.2959 96.2324 86.3459 69.9824 86.3459C43.7324 86.3459 22.4524 85.2959 22.4524 83.9659C22.4524 82.6359 43.7324 81.5859 69.9824 81.5859C96.2324 81.5859 117.547 82.6359 117.547 83.9659Z"
        fill="#67A9F1"
      />
      <path
        d="M27.9475 75.7057L31.5875 67.0957H108.588V76.8257L105.893 79.0307L103.303 77.0707H92.9425L89.7225 79.0657L88.1475 76.9657H51.4325L47.0575 79.0657L44.3975 77.2107H34.2825L30.8875 79.0657L27.9475 75.7057Z"
        fill="#0D1F27"
      />
      <path
        d="M110.722 69.9309C112.507 70.4559 112.087 73.1859 112.087 73.1859V74.2709C111.667 78.3309 105.892 79.0309 105.892 79.0309C106.067 78.3659 106.172 77.6659 106.172 76.9659C106.172 72.3809 102.427 68.6359 97.8075 68.6359C93.1875 68.6359 89.4425 72.3809 89.4425 76.9659C89.4425 77.7009 89.5475 78.4009 89.7225 79.0659H47.0575C47.2325 78.4009 47.3375 77.7009 47.3375 76.9659C47.3375 72.3809 43.5925 68.6359 38.9725 68.6359C34.3875 68.6359 30.6425 72.3809 30.6425 76.9659C30.6425 77.7009 30.7475 78.4009 30.8875 79.0659H29.1025H27.2825C26.3375 79.0659 25.5325 78.2609 25.5325 77.3159V72.5559C25.5325 71.6459 26.2325 70.9109 27.1075 70.8059C27.5625 68.5309 29.2075 65.0309 34.7725 64.4359L51.4675 63.1059C51.9575 62.6859 52.4591 62.2892 52.9725 61.9159C55.5625 59.9559 58.2925 58.2059 61.1975 56.8059C63.6125 55.6509 66.2025 54.7059 68.8625 54.1809C68.8625 54.1809 72.8525 53.6209 79.2225 53.6559C83.4575 53.6909 88.7775 53.8659 94.6575 55.1609C105.017 57.4009 110.722 69.9309 110.722 69.9309Z"
        fill="#1D5978"
      />
      <path
        d="M89.1625 71.6808L51.4325 71.3308C51.3275 68.7408 51.9575 64.2258 51.9575 64.2258L96.0575 63.0708C94.9025 64.3308 94.6925 65.0658 94.3075 66.2908C94.3075 66.2908 91.1225 67.8308 89.1625 71.6808Z"
        fill="url(#paint0_linear_51_5230)"
      />
      <path
        d="M29.1375 71.891C34.5975 66.676 38.8675 67.831 38.8675 67.831C34.0375 68.006 31.2375 71.786 31.2375 71.786L29.1375 71.891Z"
        fill="url(#paint1_linear_51_5230)"
      />
      <path
        d="M89.4775 71.7511C93.2575 66.5361 98.1225 67.8311 98.1225 67.8311C92.9775 67.6211 90.4925 71.7861 90.4925 71.7861L89.4775 71.7511Z"
        fill="url(#paint2_linear_51_5230)"
      />
      <path
        d="M50.4875 71.2607H49.4025C46.1125 64.4707 38.9375 65.1707 38.9375 65.1707L50.9425 64.2607C50.1375 68.7757 50.4875 71.2607 50.4875 71.2607Z"
        fill="url(#paint3_linear_51_5230)"
      />
      <path
        d="M101.412 66.0107C102.742 66.3607 103.967 67.0257 104.982 67.9007C106.172 68.9157 106.837 70.1757 108.517 70.4207C108.867 70.4557 110.022 70.6657 110.337 70.4207C109.077 71.4007 106.312 70.9457 106.312 70.9457C102.427 64.5757 95.9524 66.1857 95.9524 66.1857C97.7374 65.6607 99.6274 65.5207 101.412 66.0107Z"
        fill="url(#paint4_linear_51_5230)"
      />
      <path
        d="M42.3326 67.7958C43.6276 68.1458 44.8876 68.8108 45.9026 69.6858C46.9876 70.6308 48.1076 71.6458 48.6676 72.9758C48.7376 73.1858 48.8076 73.3958 48.7726 73.6408C48.7376 73.8508 48.5976 74.0608 48.3876 74.0958C48.1776 74.0958 48.0026 73.9908 47.8626 73.8508C47.5476 73.5708 47.4076 73.1858 47.2326 72.7658C47.0576 72.4158 46.8126 72.1008 46.5676 71.7858C46.1242 71.1792 45.6226 70.6192 45.0626 70.1058C44.1176 69.3008 42.9976 68.6708 41.8076 68.2508C40.3376 67.7608 38.4126 67.5158 36.8726 67.9708C38.6226 67.4458 40.5126 67.3058 42.3326 67.7958Z"
        fill="url(#paint5_linear_51_5230)"
      />
      <path
        d="M101.027 67.7958C102.357 68.1458 103.582 68.8108 104.597 69.6858C105.682 70.6308 106.837 71.6458 107.362 72.9758C107.432 73.1858 107.502 73.3958 107.502 73.6408C107.467 73.8508 107.292 74.0608 107.082 74.0958C106.872 74.0958 106.732 73.9908 106.592 73.8508C106.242 73.5708 106.102 73.1858 105.927 72.7658C105.752 72.4158 105.507 72.1008 105.262 71.7858C104.819 71.1792 104.317 70.6192 103.757 70.1058C102.812 69.3008 101.727 68.6708 100.537 68.2508C99.0324 67.7608 97.1074 67.5158 95.5674 67.9708C97.3174 67.4458 99.2074 67.3058 101.027 67.7958Z"
        fill="url(#paint6_linear_51_5230)"
      />
      <path
        d="M27.9475 70.4911C27.9475 70.4911 28.6475 64.8561 37.1525 65.4161C37.1525 65.4161 33.5475 69.0911 27.9475 70.4911Z"
        fill="#0E344B"
      />
      <path
        d="M28.5425 69.9307C28.5425 69.9307 29.0675 65.8707 35.5425 66.2907C35.5425 66.2907 32.8125 68.9157 28.5425 69.9307Z"
        fill="#FFDE17"
      />
      <path
        d="M25.5325 77.316V72.556C25.5325 72.4627 25.5441 72.381 25.5675 72.311H32.0775C31.1675 73.641 30.6425 75.251 30.6425 76.966C30.6425 77.701 30.7475 78.401 30.8875 79.066H29.1025H27.2825C26.3375 79.066 25.5325 78.261 25.5325 77.316Z"
        fill="#174A66"
      />
      <path
        d="M47.0573 79.066C47.2323 78.401 47.3373 77.701 47.3373 76.966C47.3373 75.251 46.8123 73.641 45.9023 72.311H90.8773C90.0023 73.641 89.4423 75.251 89.4423 76.966C89.4423 77.701 89.5473 78.401 89.7223 79.066H47.0573Z"
        fill="#174A66"
      />
      <path
        d="M45.6576 76.9659C45.6576 80.6759 42.6826 83.6509 38.9726 83.6509C35.2976 83.6509 32.2876 80.6759 32.2876 76.9659C32.2876 73.2909 35.2976 70.3159 38.9726 70.3159C42.6826 70.3159 45.6576 73.2909 45.6576 76.9659Z"
        fill="#2E363D"
      />
      <path
        d="M43.2426 76.9658C43.2426 79.3458 41.3526 81.2358 38.9726 81.2358C36.6276 81.2358 34.7026 79.3458 34.7026 76.9658C34.7026 74.6208 36.6276 72.6958 38.9726 72.6958C41.3526 72.6958 43.2426 74.6208 43.2426 76.9658Z"
        fill="#93A5A5"
      />
      <path
        d="M42.9977 78.4011L41.1777 77.8761L42.8227 78.8211C42.7994 78.9144 42.7527 79.0078 42.6827 79.1011L40.0927 77.6311C39.9994 77.8411 39.8477 77.9928 39.6377 78.0861L41.1077 80.6761C41.0144 80.7461 40.921 80.7928 40.8277 80.8161L39.8827 79.1711L40.4077 80.9911C40.3144 81.0378 40.221 81.0728 40.1277 81.0961L39.3227 78.2261C39.206 78.2728 39.0894 78.2961 38.9727 78.2961C38.856 78.2961 38.751 78.2728 38.6577 78.2261L37.8527 81.0961C37.7594 81.0728 37.666 81.0378 37.5727 80.9911L38.0627 79.1711L37.1527 80.8161C37.0594 80.7928 36.966 80.7461 36.8727 80.6761L38.3077 78.0861C38.121 77.9928 37.9694 77.8411 37.8527 77.6311L35.2627 79.1011C35.216 79.0078 35.1694 78.9144 35.1227 78.8211L36.7677 77.8761L34.9477 78.4011C34.9244 78.3078 34.901 78.2144 34.8777 78.1211L37.7127 77.3161C37.6894 77.1994 37.6777 77.0828 37.6777 76.9661C37.6777 76.8494 37.6894 76.7444 37.7127 76.6511L34.8777 75.8461C34.901 75.7528 34.9244 75.6594 34.9477 75.5661L36.7677 76.0561L35.1227 75.1461C35.1694 75.0528 35.216 74.9594 35.2627 74.8661L37.8527 76.3011C37.9694 76.1144 38.121 75.9628 38.3077 75.8461L36.8727 73.2561C36.966 73.2094 37.0594 73.1628 37.1527 73.1161L38.0627 74.7611L37.5727 72.9411C37.666 72.9178 37.7594 72.8944 37.8527 72.8711L38.6577 75.7061C38.751 75.6828 38.856 75.6711 38.9727 75.6711C39.0894 75.6711 39.206 75.6828 39.3227 75.7061L40.1277 72.8711C40.221 72.8944 40.3144 72.9178 40.4077 72.9411L39.8827 74.7611L40.8277 73.1161C40.921 73.1628 41.0144 73.2094 41.1077 73.2561L39.6377 75.8461C39.8477 75.9628 39.9994 76.1144 40.0927 76.3011L42.6827 74.8661C42.7527 74.9594 42.7994 75.0528 42.8227 75.1461L41.1777 76.0561L42.9977 75.5661C43.0444 75.6594 43.0794 75.7528 43.1027 75.8461L40.2327 76.6511C40.2794 76.7444 40.3027 76.8494 40.3027 76.9661C40.3027 77.0828 40.2794 77.1994 40.2327 77.3161L43.1027 78.1211C43.0794 78.2144 43.0444 78.3078 42.9977 78.4011Z"
        fill="#E2E5E7"
      />
      <path
        d="M104.493 76.9659C104.493 80.6759 101.518 83.6509 97.8076 83.6509C94.1326 83.6509 91.1226 80.6759 91.1226 76.9659C91.1226 73.2909 94.1326 70.3159 97.8076 70.3159C101.518 70.3159 104.493 73.2909 104.493 76.9659Z"
        fill="#2E363D"
      />
      <path
        d="M102.078 76.9658C102.078 79.3458 100.188 81.2358 97.8076 81.2358C95.4626 81.2358 93.5376 79.3458 93.5376 76.9658C93.5376 74.6208 95.4626 72.6958 97.8076 72.6958C100.188 72.6958 102.078 74.6208 102.078 76.9658Z"
        fill="#93A5A5"
      />
      <path
        d="M101.833 78.4011L100.013 77.8761L101.658 78.8211C101.634 78.9144 101.588 79.0078 101.518 79.1011L98.9277 77.6311C98.8343 77.8411 98.6826 77.9928 98.4726 78.0861L99.9427 80.6761C99.8493 80.7461 99.756 80.7928 99.6626 80.8161L98.7177 79.1711L99.2427 80.9911C99.1493 81.0378 99.056 81.0728 98.9626 81.0961L98.1577 78.2261C98.041 78.2728 97.9243 78.2961 97.8077 78.2961C97.691 78.2961 97.586 78.2728 97.4927 78.2261L96.6876 81.0961C96.5943 81.0728 96.501 81.0378 96.4077 80.9911L96.8976 79.1711L95.9876 80.8161C95.8943 80.7928 95.801 80.7461 95.7077 80.6761L97.1427 78.0861C96.956 77.9928 96.8043 77.8411 96.6876 77.6311L94.0976 79.1011C94.051 79.0078 94.0043 78.9144 93.9577 78.8211L95.6027 77.8761L93.7827 78.4011C93.7593 78.3078 93.736 78.2144 93.7126 78.1211L96.5476 77.3161C96.5243 77.1994 96.5126 77.0828 96.5126 76.9661C96.5126 76.8494 96.5243 76.7444 96.5476 76.6511L93.7126 75.8461C93.736 75.7528 93.7593 75.6594 93.7827 75.5661L95.6027 76.0561L93.9577 75.1461C94.0043 75.0528 94.051 74.9594 94.0976 74.8661L96.6876 76.3011C96.8043 76.1144 96.956 75.9628 97.1427 75.8461L95.7077 73.2561C95.801 73.2094 95.8943 73.1628 95.9876 73.1161L96.8976 74.7611L96.4077 72.9411C96.501 72.9178 96.5943 72.8944 96.6876 72.8711L97.4927 75.7061C97.586 75.6828 97.691 75.6711 97.8077 75.6711C97.9243 75.6711 98.041 75.6828 98.1577 75.7061L98.9626 72.8711C99.056 72.8944 99.1493 72.9178 99.2427 72.9411L98.7177 74.7611L99.6626 73.1161C99.756 73.1628 99.8493 73.2094 99.9427 73.2561L98.4726 75.8461C98.6826 75.9628 98.8343 76.1144 98.9277 76.3011L101.518 74.8661C101.588 74.9594 101.634 75.0528 101.658 75.1461L100.013 76.0561L101.833 75.5661C101.879 75.6594 101.914 75.7528 101.938 75.8461L99.0677 76.6511C99.1143 76.7444 99.1376 76.8494 99.1376 76.9661C99.1376 77.0828 99.1143 77.1994 99.0677 77.3161L101.938 78.1211C101.914 78.2144 101.879 78.3078 101.833 78.4011Z"
        fill="#E2E5E7"
      />
      <path
        d="M53.0076 76.9659L84.1926 75.6009C84.1926 75.6009 86.0126 75.4259 86.9576 73.2559C86.9576 73.2559 87.3076 76.2659 83.2476 76.3709L53.0076 76.9659Z"
        fill="#1D5978"
      />
      <path
        d="M73.8675 66.0458H70.2625C69.8775 66.0458 69.5625 65.7308 69.5625 65.3458C69.5625 64.9608 69.8775 64.6108 70.2625 64.6108H73.8675C74.2525 64.6108 74.5675 64.9608 74.5675 65.3458C74.5675 65.7308 74.2525 66.0458 73.8675 66.0458Z"
        fill="#1D5978"
      />
      <path
        d="M74.5675 65.3809C74.5675 65.7659 74.2525 66.0459 73.8675 66.0459H70.2625C69.9125 66.0459 69.5975 65.7659 69.5625 65.3809H74.5675Z"
        fill="#0E344B"
      />
      <path
        d="M91.5775 65.4858H88.0075C87.5875 65.4858 87.2725 65.1708 87.2725 64.7858C87.2725 64.4008 87.5875 64.0508 88.0075 64.0508H91.5775C91.9625 64.0508 92.3125 64.4008 92.3125 64.7858C92.3125 65.1708 91.9625 65.4858 91.5775 65.4858Z"
        fill="#1D5978"
      />
      <path
        d="M92.2775 64.8208C92.2775 65.2058 91.9625 65.4858 91.5775 65.4858H88.0075C87.6225 65.4858 87.3075 65.2058 87.2725 64.8208H92.2775Z"
        fill="#0E344B"
      />
      <path
        d="M28.8925 75.391C28.8925 76.056 28.3325 76.616 27.6325 76.616H25.5325V74.166H27.6325C28.3325 74.166 28.8925 74.691 28.8925 75.391Z"
        fill="#0D1F27"
      />
      <path
        d="M102.183 60.3759C100.538 58.8359 100.818 58.4859 101.168 58.1709C102.568 59.1509 103.793 60.2709 104.878 61.4259C103.793 61.6709 102.183 60.3759 102.183 60.3759Z"
        fill="#0D1F27"
      />
      <path
        d="M99.1025 57.1209C99.1025 57.1209 106.173 56.9109 100.713 59.0459C100.713 59.0459 99.9075 59.3259 99.1025 57.1209Z"
        fill="#1D5978"
      />
      <path
        d="M104.562 65.9408C103.617 65.9408 102.812 65.1358 102.812 64.1908V64.1208C102.812 63.1758 103.617 62.4058 104.562 62.4058H105.753C106.803 63.5958 107.677 64.8208 108.412 65.9408H104.562Z"
        fill="#EE3837"
      />
      <path
        d="M112.087 74.2709C111.667 78.3309 105.892 79.0309 105.892 79.0309C106.067 78.3659 106.172 77.6659 106.172 76.9659C106.172 75.3209 105.647 73.7459 104.807 72.4509H107.957C107.957 72.4509 111.037 72.4509 111.982 71.2959C112.227 72.2059 112.087 73.1859 112.087 73.1859V74.2709Z"
        fill="#174A66"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.4124 77.8061H51.9574V77.7361C50.5224 70.9811 51.9224 63.8411 51.9574 63.7711V63.7361H51.9924C52.5174 63.2811 53.0074 62.8961 53.4624 62.5461C56.1224 60.5161 58.8174 58.8361 61.5474 57.5411C64.1024 56.3161 66.6224 55.4411 69.0024 54.9511C71.0324 54.5661 73.0624 54.4961 74.8124 54.4961C75.5124 54.4961 76.2124 54.5311 76.8774 54.5311H76.9824C77.5891 54.5311 78.1841 54.5428 78.7674 54.5661H79.1524C83.4924 54.5661 87.9724 54.5661 92.1374 55.6161C93.8174 56.0711 95.4624 56.5611 97.0724 57.1211C97.1424 57.1561 98.6824 57.6811 98.9624 58.9061C99.1374 59.7811 98.6824 60.7611 97.5274 61.8461C97.4924 61.8461 94.9374 63.7011 94.4124 66.3261V66.3611L94.3774 66.3961C94.3074 66.3961 88.7424 68.6011 87.4124 77.7361V77.8061ZM52.0974 77.6661H87.2724C88.6024 68.8811 93.7824 66.4661 94.2724 66.2561C94.8324 63.5961 97.4224 61.7411 97.4224 61.7061C98.5424 60.6911 98.9974 59.7461 98.8224 58.9411C98.5424 57.7861 97.0374 57.2961 97.0374 57.2961C95.4274 56.7361 93.7824 56.2111 92.1024 55.7911C87.9724 54.7411 83.4924 54.7061 79.1524 54.7061H78.8024C78.1957 54.7061 77.5891 54.7061 76.9824 54.7061H76.9124C76.2124 54.6711 75.5124 54.6711 74.8124 54.6711C73.0974 54.6711 71.0674 54.7061 69.0374 55.1261C66.6574 55.5811 64.1374 56.4561 61.6174 57.6811C58.9224 58.9761 56.1924 60.6561 53.5674 62.6511C53.1124 63.0011 52.6224 63.3861 52.0974 63.8411C52.0274 64.2961 50.7324 71.1561 52.0974 77.6661Z"
        fill="#0E344B"
      />
      <path
        d="M75.2324 77.7008C75.1274 68.7758 76.6674 62.4758 76.6674 62.4058L76.8074 62.4408C76.8074 62.5108 75.2674 68.7758 75.3724 77.7008H75.2324Z"
        fill="#0E344B"
      />
      <path
        d="M94.0274 61.9861L54.0574 63.2811C56.6474 61.3211 59.3074 59.6761 61.9324 58.3811C64.4174 57.1911 66.8674 56.3511 69.2124 55.8961C71.1374 55.5111 73.1324 55.4761 74.8124 55.4761C75.5124 55.4761 76.2007 55.4761 76.8774 55.4761C77.5074 55.4994 78.1374 55.5111 78.7674 55.5111H79.1524C83.4224 55.5111 87.8674 55.5111 91.9274 56.5611C93.4907 56.9577 95.019 57.4244 96.5124 57.9611C96.5124 57.9611 98.1924 58.6961 96.9324 60.5511C96.9324 60.5511 96.2324 61.8811 94.0274 61.9861Z"
        fill="#0D1F27"
      />
      <path
        d="M59.7626 63.281C59.7626 64.331 58.3626 65.206 56.6476 65.206C54.9676 65.206 54.6526 64.331 54.6526 63.281C54.6526 62.196 54.9676 61.356 56.6476 61.356C58.3626 61.356 59.7626 62.196 59.7626 63.281Z"
        fill="url(#paint7_radial_51_5230)"
      />
      <path
        d="M85.8724 62.2661L76.0374 62.5811L69.3174 55.8611C71.2074 55.5111 73.1674 55.4761 74.8124 55.4761C75.5124 55.4761 76.2007 55.4761 76.8774 55.4761C77.5074 55.4994 78.1374 55.5111 78.7674 55.5111H79.1174L85.8724 62.2661Z"
        fill="#223139"
      />
      <path
        d="M77.1925 55.4761C77.7291 55.4994 78.2541 55.5111 78.7675 55.5111H79.1525C79.3858 55.5111 79.6075 55.5111 79.8175 55.5111L78.5925 62.5111L74.9175 62.6161L77.1925 55.4761Z"
        fill="#174A66"
      />
      <defs>
        <linearGradient
          id="paint0_linear_51_5230"
          x1="74.5675"
          y1="64.5758"
          x2="74.5675"
          y2="70.9808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#436E8B" />
          <stop offset="1" stop-color="#1D5978" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_51_5230"
          x1="38.6225"
          y1="69.826"
          x2="33.8625"
          y2="73.046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#436E8B" />
          <stop offset="1" stop-color="#1D5978" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_51_5230"
          x1="98.1925"
          y1="70.0011"
          x2="93.9225"
          y2="72.8711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#436E8B" />
          <stop offset="1" stop-color="#1D5978" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_51_5230"
          x1="44.8525"
          y1="63.0357"
          x2="50.0675"
          y2="66.0107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#174A66" />
          <stop offset="1" stop-color="#1D5978" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_51_5230"
          x1="99.8024"
          y1="63.6307"
          x2="113.207"
          y2="68.8457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#174A66" />
          <stop offset="1" stop-color="#1D5978" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_51_5230"
          x1="45.0626"
          y1="77.3508"
          x2="38.7626"
          y2="72.3808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#174A66" />
          <stop offset="1" stop-color="#1D5978" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_51_5230"
          x1="103.792"
          y1="77.3508"
          x2="97.4574"
          y2="72.3808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#174A66" />
          <stop offset="1" stop-color="#1D5978" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_51_5230"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(58.2576 62.301) scale(4.2 4.2)"
        >
          <stop stop-color="#436E8B" />
          <stop offset="1" stop-color="#164661" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Compact;
