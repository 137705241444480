const Van = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M135.818 94.4825C135.818 95.8125 106.348 96.8625 69.9826 96.8625C33.6526 96.8625 4.18262 95.8125 4.18262 94.4825C4.18262 93.1525 33.6526 92.1025 69.9826 92.1025C106.348 92.1025 135.818 93.1525 135.818 94.4825Z"
        fill="#67A9F1"
      />
      <path
        d="M20.6326 86.7477V72.8877H120.278V81.4977L114.923 89.7577L112.123 87.3427H99.4526L97.0026 89.7577L93.8876 86.8177H44.1876L41.2476 89.7577L39.1826 87.4127H25.6726L23.3276 89.7577L20.6326 86.7477Z"
        fill="#0D1F27"
      />
      <path
        d="M40.3027 86.2577C40.3027 90.7027 36.6977 94.2727 32.2877 94.2727C27.8427 94.2727 24.2727 90.7027 24.2727 86.2577C24.2727 81.8477 27.8427 78.2427 32.2877 78.2427C36.6977 78.2427 40.3027 81.8477 40.3027 86.2577Z"
        fill="#2E363D"
      />
      <path
        d="M37.4328 86.2578C37.4328 89.0928 35.1228 91.4028 32.2878 91.4028C29.4528 91.4028 27.1428 89.0928 27.1428 86.2578C27.1428 83.4228 29.4528 81.1128 32.2878 81.1128C35.1228 81.1128 37.4328 83.4228 37.4328 86.2578Z"
        fill="#93A5A5"
      />
      <path
        d="M37.1178 87.9728L34.9128 87.3427L36.9078 88.4627C36.8611 88.5794 36.8028 88.6961 36.7328 88.8127L33.6528 87.0627C33.5128 87.2961 33.3261 87.4828 33.0928 87.6228L34.8078 90.7027C34.7145 90.7727 34.6095 90.8311 34.4928 90.8777L33.3728 88.9177L34.0028 91.0877C33.8861 91.1344 33.7695 91.1694 33.6528 91.1927L32.6728 87.7627C32.5561 87.8094 32.4278 87.8327 32.2878 87.8327C32.1478 87.8327 32.0195 87.8094 31.9028 87.7627L30.9228 91.1927C30.8061 91.1694 30.6895 91.1344 30.5728 91.0877L31.2028 88.9177L30.0828 90.8777C29.9661 90.8311 29.8495 90.7727 29.7328 90.7027L31.4828 87.6228C31.2495 87.4828 31.0628 87.2961 30.9228 87.0627L27.8428 88.8127C27.7728 88.6961 27.7145 88.5794 27.6678 88.4627L29.6278 87.3427L27.4578 87.9728C27.4111 87.8561 27.3761 87.7394 27.3528 87.6228L30.7828 86.6427C30.7361 86.5261 30.7128 86.3977 30.7128 86.2577C30.7128 86.1177 30.7361 85.9894 30.7828 85.8728L27.3528 84.8927C27.3761 84.7761 27.4111 84.6594 27.4578 84.5427L29.6278 85.1728L27.6678 84.0527C27.7145 83.9361 27.7728 83.8311 27.8428 83.7377L30.9228 85.4527C31.0628 85.2194 31.2495 85.0327 31.4828 84.8927L29.7328 81.8127C29.8495 81.7427 29.9661 81.6844 30.0828 81.6377L31.2028 83.6327L30.5728 81.4277C30.6895 81.3811 30.8061 81.3461 30.9228 81.3228L31.9028 84.7527C32.0195 84.7061 32.1478 84.6827 32.2878 84.6827C32.4278 84.6827 32.5561 84.7061 32.6728 84.7527L33.6528 81.3228C33.7695 81.3461 33.8861 81.3811 34.0028 81.4277L33.3728 83.6327L34.4928 81.6377C34.6095 81.6844 34.7145 81.7427 34.8078 81.8127L33.0928 84.8927C33.3261 85.0327 33.5128 85.2194 33.6528 85.4527L36.7328 83.7377C36.8028 83.8311 36.8611 83.9361 36.9078 84.0527L34.9128 85.1728L37.1178 84.5427C37.1645 84.6594 37.1995 84.7761 37.2228 84.8927L33.7928 85.8728C33.8395 85.9894 33.8628 86.1177 33.8628 86.2577C33.8628 86.3977 33.8395 86.5261 33.7928 86.6427L37.2228 87.6228C37.1995 87.7394 37.1645 87.8561 37.1178 87.9728Z"
        fill="#E2E5E7"
      />
      <path
        d="M113.978 86.2577C113.978 90.7027 110.408 94.2727 105.963 94.2727C101.553 94.2727 97.9478 90.7027 97.9478 86.2577C97.9478 81.8477 101.553 78.2427 105.963 78.2427C110.408 78.2427 113.978 81.8477 113.978 86.2577Z"
        fill="#2E363D"
      />
      <path
        d="M111.108 86.2578C111.108 89.0928 108.798 91.4028 105.963 91.4028C103.128 91.4028 100.818 89.0928 100.818 86.2578C100.818 83.4228 103.128 81.1128 105.963 81.1128C108.798 81.1128 111.108 83.4228 111.108 86.2578Z"
        fill="#93A5A5"
      />
      <path
        d="M110.793 87.9728L108.623 87.3427L110.583 88.4627C110.536 88.5794 110.49 88.6961 110.443 88.8127L107.328 87.0627C107.188 87.2961 107.001 87.4828 106.768 87.6228L108.518 90.7027C108.401 90.7727 108.285 90.8311 108.168 90.8777L107.048 88.9177L107.678 91.0877C107.561 91.1344 107.444 91.1694 107.328 91.1927L106.348 87.7627C106.231 87.8094 106.103 87.8327 105.963 87.8327C105.823 87.8327 105.694 87.8094 105.578 87.7627L104.598 91.1927C104.481 91.1694 104.365 91.1344 104.248 91.0877L104.878 88.9177L103.758 90.8777C103.641 90.8311 103.536 90.7727 103.443 90.7027L105.158 87.6228C104.924 87.4828 104.749 87.2961 104.633 87.0627L101.518 88.8127C101.448 88.6961 101.39 88.5794 101.343 88.4627L103.338 87.3427L101.133 87.9728C101.086 87.8561 101.051 87.7394 101.028 87.6228L104.458 86.6427C104.411 86.5261 104.388 86.3977 104.388 86.2577C104.388 86.1177 104.411 85.9894 104.458 85.8728L101.028 84.8927C101.051 84.7761 101.086 84.6594 101.133 84.5427L103.338 85.1728L101.343 84.0527C101.39 83.9361 101.448 83.8311 101.518 83.7377L104.633 85.4527C104.749 85.2194 104.924 85.0327 105.158 84.8927L103.443 81.8127C103.536 81.7427 103.641 81.6844 103.758 81.6377L104.878 83.6327L104.248 81.4277C104.365 81.3811 104.481 81.3461 104.598 81.3228L105.578 84.7527C105.694 84.7061 105.823 84.6827 105.963 84.6827C106.103 84.6827 106.231 84.7061 106.348 84.7527L107.328 81.3228C107.444 81.3461 107.561 81.3811 107.678 81.4277L107.048 83.6327L108.168 81.6377C108.285 81.6844 108.401 81.7427 108.518 81.8127L106.768 84.8927C107.001 85.0327 107.188 85.2194 107.328 85.4527L110.443 83.7377C110.49 83.8311 110.536 83.9361 110.583 84.0527L108.623 85.1728L110.793 84.5427C110.84 84.6594 110.875 84.7761 110.898 84.8927L107.468 85.8728C107.515 85.9894 107.538 86.1177 107.538 86.2577C107.538 86.3977 107.515 86.5261 107.468 86.6427L110.898 87.6228C110.875 87.7394 110.84 87.8561 110.793 87.9728Z"
        fill="#E2E5E7"
      />
      <path
        d="M129.168 83.6327V86.0477C129.168 86.0477 128.888 87.9027 125.318 88.3227L115.308 89.7577H114.923C115.343 88.6727 115.588 87.4827 115.588 86.2577C115.588 80.9377 111.283 76.6327 105.963 76.6327C100.643 76.6327 96.3377 80.9377 96.3377 86.2577C96.3377 87.4827 96.5827 88.6727 97.0027 89.7577H41.2477C41.6677 88.6727 41.9127 87.4827 41.9127 86.2577C41.9127 80.9377 37.6077 76.6327 32.2877 76.6327C26.9677 76.6327 22.6627 80.9377 22.6627 86.2577C22.6627 87.4827 22.9077 88.6727 23.3277 89.7577H10.9377C10.9377 89.7577 9.50269 89.1977 9.50269 87.4827V84.4727C9.50269 84.4727 9.57269 82.4077 11.5327 82.4077V72.1177C11.5327 72.1177 11.9177 66.4827 15.4177 63.0877C15.4177 63.0877 35.9977 43.1377 50.8027 43.1377H118.738C118.738 43.1377 123.358 43.4877 125.493 49.5777C125.493 49.5777 130.638 61.1627 127.733 82.0927C127.733 82.0927 129.168 82.0577 129.168 83.6327Z"
        fill="#E2E6E6"
      />
      <path
        d="M96.3728 85.9077C96.3728 86.0127 96.3378 86.1527 96.3378 86.2577C96.3378 87.4827 96.5828 88.6727 97.0028 89.7577H41.2478C41.6678 88.6727 41.9128 87.4827 41.9128 86.2577C41.9128 86.1527 41.9128 86.0127 41.8778 85.9077H96.3728Z"
        fill="#C2C7CD"
      />
      <path
        d="M9.50269 87.4827V85.9077H22.6627C22.6627 86.0127 22.6627 86.1527 22.6627 86.2577C22.6627 87.4827 22.9077 88.6727 23.3277 89.7577H10.9377C10.9377 89.7577 9.50269 89.1977 9.50269 87.4827Z"
        fill="#C2C7CD"
      />
      <path
        d="M115.553 85.8375L126.333 85.0675C126.333 85.0675 128.013 84.8926 129.168 84.1226V86.0476C129.168 86.0476 128.888 87.9026 125.318 88.3226L115.308 89.7576H114.923C115.343 88.6726 115.588 87.4826 115.588 86.2576C115.588 86.1176 115.588 85.9775 115.553 85.8375Z"
        fill="#C2C7CD"
      />
      <path
        d="M23.0127 88.7428C23.106 89.0928 23.211 89.4311 23.3277 89.7578H10.9377C10.9377 89.7578 9.50269 89.1978 9.50269 87.4828V86.4678C9.50269 88.1828 10.9377 88.7428 10.9377 88.7428H23.0127Z"
        fill="#797C7E"
      />
      <path
        d="M129.168 85.0327V86.0477C129.168 86.0477 128.888 87.9027 125.318 88.3227L115.308 89.7577H114.923C115.039 89.431 115.144 89.0927 115.238 88.7427H115.308L125.318 87.3427C128.888 86.8877 129.168 85.0327 129.168 85.0327Z"
        fill="#797C7E"
      />
      <path
        d="M96.6878 88.7427C96.7811 89.0927 96.8861 89.431 97.0028 89.7577H41.2478C41.3645 89.431 41.4695 89.0927 41.5628 88.7427H96.6878Z"
        fill="#797C7E"
      />
      <path
        d="M39.6028 65.5376H19.3728C19.8628 63.8576 22.2778 62.1776 22.2778 62.1776L31.4478 54.9326C33.7928 53.1126 34.9828 53.5326 34.9828 53.5326H42.3678V62.2476C42.3678 62.2476 42.7878 65.2926 39.6028 65.5376Z"
        fill="#0D1F27"
      />
      <path
        d="M41.5627 46.1825C44.7477 44.8875 47.8977 44.0825 50.8027 44.0825H118.703C118.948 44.0825 120.768 44.3625 122.448 46.1825H41.5627Z"
        fill="url(#paint0_linear_51_5226)"
      />
      <path
        d="M11.5327 78.3829V72.1179C11.5327 72.1179 11.6027 71.2429 11.8827 69.9829H14.1927C15.1727 69.9829 15.9427 70.7879 15.9427 71.7679V76.6329C15.9427 77.6129 15.1727 78.3829 14.1927 78.3829H11.5327Z"
        fill="#223139"
      />
      <path
        d="M14.4727 73.9725V75.2325C14.4727 76.1775 13.7027 76.9825 12.7227 76.9825H11.7777C11.6844 76.9825 11.6027 76.9708 11.5327 76.9475V72.2225C11.6027 72.2225 11.6844 72.2108 11.7777 72.1875H12.7227C13.7027 72.1875 14.4727 72.9925 14.4727 73.9725Z"
        fill="#FFDE17"
      />
      <path
        d="M125.668 77.6476V70.5776C125.668 69.6326 126.473 68.8276 127.453 68.8276H128.608C128.643 71.9776 128.503 75.5126 128.083 79.3976H127.453C126.473 79.3976 125.668 78.5926 125.668 77.6476Z"
        fill="#EE3837"
      />
      <path
        d="M117.688 86.2578C117.688 79.7828 112.438 74.5328 105.963 74.5328C105.963 74.5328 117.653 72.7828 119.508 86.1528L117.688 86.2578Z"
        fill="url(#paint1_linear_51_5226)"
      />
      <path
        d="M30.7126 55.4927L40.5826 65.3277C40.3026 65.421 39.9759 65.491 39.6026 65.5377H33.6526L26.7576 58.6077L30.7126 55.4927Z"
        fill="#223139"
      />
      <path
        d="M120.698 84.2276C120.698 84.2276 119.263 73.1676 107.643 73.1676C107.643 73.1676 95.7777 72.1526 93.5377 83.6676H46.0427V54.1626L49.6127 62.2476C49.6127 62.2476 51.2577 65.9576 54.3727 66.5526H76.7727V68.0926L120.453 68.1976C120.453 68.1976 122.623 68.2676 124.163 67.1126C124.163 67.1126 126.088 78.6976 120.698 84.2276Z"
        fill="url(#paint2_linear_51_5226)"
      />
      <path
        d="M49.0878 70.3677V74.6027C49.0878 75.0577 48.7028 75.4427 48.2478 75.4427C47.7578 75.4427 47.3728 75.0577 47.3728 74.6027V70.3677C47.3728 69.8777 47.7578 69.4927 48.2478 69.4927C48.7028 69.4927 49.0878 69.8777 49.0878 70.3677Z"
        fill="#E2E6E6"
      />
      <path
        d="M48.2827 69.5278C48.7377 69.5278 49.0877 69.9128 49.0877 70.3678V74.6028C49.0877 75.0578 48.7377 75.4078 48.2827 75.4428V69.5278Z"
        fill="#9EA4AA"
      />
      <path
        d="M97.0727 77.0878H52.1327C51.7127 77.0878 51.3977 76.7378 51.3977 76.3178C51.3977 75.8978 51.7127 75.5479 52.1327 75.5479H97.0727C97.4927 75.5479 97.8427 75.8978 97.8427 76.3178C97.8427 76.7378 97.4927 77.0878 97.0727 77.0878Z"
        fill="#C2C7CD"
      />
      <path
        d="M76.7727 68.2328V68.0928H121.328C123.183 67.9178 124.513 67.0778 125.213 65.6428C125.738 64.5578 125.703 63.5078 125.703 63.5078C125.843 58.4678 124.688 53.9878 124.653 53.9528C124.128 51.2578 120.103 51.3978 120.068 51.3978H44.5027V51.2228H120.068C120.103 51.2228 124.268 51.0828 124.828 53.9178C124.828 53.9528 126.018 58.4328 125.878 63.5078C125.913 64.9778 125.038 67.9178 121.328 68.2328H76.7727Z"
        fill="#9EA4AA"
      />
      <path
        d="M76.8428 88.2525H49.7527C45.9377 88.0075 45.9377 84.1225 45.9377 84.0875V51.3625H46.0777V84.0875C46.0777 84.2625 46.1127 87.8325 49.7527 88.0775H76.7027V51.2925H76.8428V88.2525Z"
        fill="#9EA4AA"
      />
      <path
        d="M121.363 65.5377H56.1576C52.7276 65.5377 50.8376 61.5127 50.8376 61.5127L46.9526 53.5327H121.363C121.363 53.5327 122.588 53.5327 122.728 54.7927C122.728 54.7927 123.498 59.7277 123.463 63.6827C123.463 63.6827 123.673 65.5727 121.363 65.5377Z"
        fill="#0D1F27"
      />
      <path
        d="M101.063 65.5377H90.4576L78.4526 53.5327H89.0576L101.063 65.5377Z"
        fill="#223139"
      />
      <path
        d="M56.3328 53.5327H63.4028L75.4078 65.5377H68.3028L56.3328 53.5327Z"
        fill="#223139"
      />
      <path
        d="M117.863 65.5377H110.793L98.7878 53.5327H105.893L117.863 65.5377Z"
        fill="#223139"
      />
      <path
        d="M38.6577 79.0478C36.9427 77.5428 34.7377 76.6328 32.2877 76.6328C27.5277 76.6328 23.5727 80.0978 22.8027 84.6478H16.8527L16.7827 67.0078H44.5027V69.3178C44.5027 69.3178 44.1177 75.4078 38.6577 79.0478Z"
        fill="url(#paint3_linear_51_5226)"
      />
      <path
        d="M41.4577 69.3177H37.2227C36.7327 69.3177 36.3477 68.9327 36.3477 68.4777C36.3477 68.0227 36.7327 67.6377 37.2227 67.6377H41.4577C41.9127 67.6377 42.2977 68.0227 42.2977 68.4777C42.2977 68.9327 41.9127 69.3177 41.4577 69.3177Z"
        fill="#E2E6E6"
      />
      <path
        d="M42.2977 68.5127C42.2627 68.9677 41.9127 69.3177 41.4577 69.3177H37.2227C36.7677 69.3177 36.3827 68.9677 36.3477 68.5127H42.2977Z"
        fill="#9EA4AA"
      />
      <path
        d="M25.6727 65.4326C25.6727 66.4826 24.3078 67.3576 22.5928 67.3576C20.8778 67.3576 20.5627 66.4826 20.5627 65.4326C20.5627 64.3476 20.8778 63.4727 22.5928 63.4727C24.3078 63.4727 25.6727 64.3476 25.6727 65.4326Z"
        fill="url(#paint4_radial_51_5226)"
      />
      <path
        d="M43.7326 86.2578C43.7326 79.7828 38.4826 74.5328 32.0076 74.5328C32.0076 74.5328 43.6976 72.7828 45.5176 86.1528L43.7326 86.2578Z"
        fill="url(#paint5_linear_51_5226)"
      />
      <path
        d="M43.6276 72.8176C44.4326 70.7176 44.4327 69.0727 44.4327 69.0727V51.3977L34.6677 51.3626C34.0727 51.3626 33.4426 51.3976 32.8477 51.6076C32.7543 51.6543 32.661 51.701 32.5677 51.7477C31.6227 52.2027 30.7476 52.9376 29.8726 53.6376C29.5926 53.8826 29.3127 54.1277 29.0327 54.3727L19.6177 61.8277C16.8877 64.2077 16.8526 67.0076 16.8526 67.0076V84.5777H22.8026C22.8026 84.6243 22.8026 84.671 22.8026 84.7176H16.7126V67.0076C16.7126 66.9726 16.7126 66.3076 17.0626 65.2926C17.3776 64.3476 18.0776 62.9827 19.5476 61.7227L28.9276 54.2326C29.2076 54.0226 29.5226 53.7776 29.8026 53.5326C30.6426 52.7976 31.5176 52.0626 32.4977 51.6076C32.6143 51.561 32.7193 51.5143 32.8126 51.4676C33.4076 51.2576 34.0727 51.2227 34.6677 51.2227H37.8526H44.5727V69.0727C44.5727 69.1077 44.6076 75.0576 38.7276 79.1176C38.681 79.0943 38.6343 79.0593 38.5876 79.0126C41.5276 77.0176 42.9276 74.5676 43.6276 72.8176Z"
        fill="#9EA4AA"
      />
      <path
        d="M121.223 47.6177C121.398 48.0027 121.608 48.3877 121.748 48.8427H37.6077C38.2843 48.4227 38.961 48.0143 39.6377 47.6177H121.223Z"
        fill="#C2C7CD"
      />
      <defs>
        <linearGradient
          id="paint0_linear_51_5226"
          x1="31.2727"
          y1="44.0825"
          x2="108.833"
          y2="44.0825"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_51_5226"
          x1="110.128"
          y1="91.4028"
          x2="103.793"
          y2="83.2129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C2C7CD" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_51_5226"
          x1="90.8077"
          y1="59.1326"
          x2="90.8077"
          y2="74.1126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_51_5226"
          x1="36.2427"
          y1="67.6728"
          x2="36.2427"
          y2="73.6228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_51_5226"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.6427 64.3477) scale(3.29 3.28999)"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#C3C9C9" />
        </radialGradient>
        <linearGradient
          id="paint5_linear_51_5226"
          x1="35.5776"
          y1="91.1578"
          x2="29.2076"
          y2="82.2328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C2C7CD" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Van;
