import { ReactNode } from "react";

export type Props = {
  title: string;
  children: ReactNode;
  rightAccessory?: ReactNode;
};

const Section = ({ title, children, rightAccessory }: Props) => {
  return (
    <div className="my-8">
      <div className="flex justify-between items-center">
        <p className="mb-3 text-xs text-gray-700 font-semibold">{title}</p>
        <div>{rightAccessory}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Section;
