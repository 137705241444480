import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "../../node_modules/leaflet/dist/leaflet.css";
import { useRef, useMemo } from "react";

export type Props = {
  lat?: number;
  lng?: number;
  draggable?: boolean;
  still?: boolean;
  onFinishDrag?: (loc: { lat: number; lng: number }) => void;
};

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const Map = ({
  lat = 42.2808,
  lng = -83.743,
  draggable = false,
  still = true,
  onFinishDrag = () => {},
}: Props) => {
  const markerRef = useRef<L.Marker>(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onFinishDrag({
            lat: marker.getLatLng().lat,
            lng: marker.getLatLng().lng,
          });
        }
      },
    }),
    []
  );

  return (
    <div className="w-full h-80 rounded-md overflow-hidden">
      <MapContainer
        center={[lat, lng]}
        zoom={16}
        scrollWheelZoom={!still}
        className="w-100 h-50"
        style={{ width: "100%", height: "100%" }}
        dragging={!still}
        zoomControl={!still}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          ref={markerRef}
          eventHandlers={eventHandlers}
          draggable={draggable}
          icon={DefaultIcon}
          position={[lat, lng]}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
