import {
  GET_AVAILABLE_SPOTS,
  Spot as SpotType,
} from "../queries/getAvailableSpotsQuery";
import { useQuery } from "@apollo/client";
import {
  filterByDates,
  filterByPosition,
  calculateDistance,
} from "../helpers/Filters";
import { SearchQuery } from "../components/search";

export const useSpotSearch = (query: SearchQuery) => {
  const { loading, error, data } = useQuery<{ spots: SpotType[] }>(
    GET_AVAILABLE_SPOTS
  );


  let spots = data?.spots ?? [];

  const { dates, location } = query;

  if (dates) {
    spots = spots.filter((spt) =>
      filterByDates(
        spt.period,
        [],
        { start: dates.start, end: dates.end },
        0,
        false,
        spt.pricing,
        spt.minimumLeaseRange
      )
    );
  }

  if (location) {
    spots = spots.filter((spt) =>
      filterByPosition(
        spt.position,
        { lng: Number(location.lng), lat: Number(location.lat) },
        8046.72
      )
    );
    spots = spots.sort((a, b) => {
      return (
        calculateDistance(a.position, {
          lng: Number(location.lng),
          lat: Number(location.lat),
        }) -
        calculateDistance(b.position, {
          lng: Number(location.lng),
          lat: Number(location.lat),
        })
      );
    });
  }

  return { loading, error, spots };
};
