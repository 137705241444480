import { gql } from "@apollo/client";

export const GET_SPOT = gql`
  query GetSpotQuery($spotId: ID!) {
    spot(id: $spotId) {
      id
      name
      address
      description
      pricing {
        daily
        weekly
        monthly
        lease
      }
      upfrontOnly
      period {
        start
        end
        fullRange
      }
      images {
        imageId
        href
      }
      position {
        lng
        lat
      }
      carType
      spotType
      sheltered
      parkingPass
      restroom
      tailgating
      ownerId
    }
  }
`;

export interface Spot {
  id: string;
  name: string;
  address: string;
  description: string;
  pricing: {
    daily: number;
    weekly: number;
    monthly: number;
    lease: number;
  };
  upfrontOnly: boolean;
  period: {
    start: string;
    end: string;
    fullRange: boolean;
  };
  images: [
    {
      imageId: string;
      href: string;
    }
  ];
  position: {
    lng: number;
    lat: number;
  };
  carType: number;
  spotType: number;
  sheltered: boolean;
  parkingPass: boolean;
  restroom: boolean;
  tailgating: boolean;
  ownerId: string;
}

export const getSpotType = (spotType: number) => {
  switch (spotType) {
    case 0:
      return "Driveway";
    case 1:
      return "Apartment";
    case 2:
      return "Lot";
    case 3:
      return "Garage";
    default:
      return "Unknown";
  }
};

export const getSpotSize = (carType: number) => {
  switch (carType) {
    case 0:
      return "Compact";
    case 1:
      return "Sedan";
    case 2:
      return "SUV";
    case 3:
      return "Pickup";
    case 4:
      return "Van";
    case 5:
      return "RV";
    default:
      return "Unknown";
  }
};
