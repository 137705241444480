import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import {useForm} from 'react-hook-form';
import type { SubmitHandler } from "react-hook-form";
import { IoMdClose} from 'react-icons/io';
import { Auth } from 'aws-amplify';
import { useState , useEffect} from "react";
import Popup from "./login/popup";
import PopupHeader from "./login/popupheader";
import Textfield from "./textfield/textfield";
import Submitbutton from "./login/submitbutton";
import FocusTrap from "focus-trap-react"




export type Props = {
    email: string;
    pageChanger : React.Dispatch<React.SetStateAction<string>>;
    setLoginShown : React.Dispatch<React.SetStateAction<boolean>>;
    setEmail : React.Dispatch<React.SetStateAction<string>>;
}

const ForgotPassword = ({ email, pageChanger, setLoginShown, setEmail }: Props) => {

    const auth = useContext(AuthContext);
    const {register, handleSubmit,reset, formState:{errors}} = useForm<Props>({defaultValues: {
        email : "", 
    }});

    const [loading, setLoading] = useState(false);    

    // const [anerror, setError] = useState(false);

    const onSubmit: SubmitHandler<Props> =  async (data) => {
        // Send confirmation code to user's emailD
        setLoading(true);
        Auth.forgotPassword(data.email)
        .then((newdata) => pageChanger("VerifyChangePassword"))
        .then(() => setLoading(false))
        .then(() => reset())
        .then(() => setEmail(data.email))
        .catch((err) => {
            console.log('error')
            setEmail(data.email);
            pageChanger("VerifyChangePassword"); //show next on error for security reasons
            setLoading(false);
        });
    }

    const hide = () => {
        reset();
        setLoginShown(false);
    }

    const login = () => {
        reset();
        pageChanger("Login");
    }

    useEffect(() => {
        const handleKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            hide();
          }
        };
    
        window.addEventListener('keydown', handleKey);
    
        return () => {
          window.removeEventListener('keydown', handleKey);
        };
      }, [hide]);


    return (
        <div>
            <Popup height = "50">
                <FocusTrap active = {true} focusTrapOptions={{clickOutsideDeactivates : false, allowOutsideClick : false}}>
                    <div>
                        <PopupHeader popupSize = "medium" boldtext = "Forgot your password?" onClick = {hide}></PopupHeader>
                        <div className= " ml-22 sm:ml-18 mr-22 sm:mr-18 h-[5vh] flex flex-col text-center mt-[1vh]">
                            <span className="text-1xl w-full font-poppins mb-[5vh] z-0 "> 
                            Enter your email address associated with your account to reset your password</span>
                        </div>


                        <form noValidate= {true} onSubmit={handleSubmit(onSubmit)} className="!top-12">
                            <Textfield label = "Email Address" 
                            placeholder="Email Address" 
                            {...register("email", { required:  true})}
                            type="email"     
                            id="email"/>
                            <Submitbutton text="Send" loading = {loading}/>
                            
                        </form>
                        <p className="text-center mt-[5vh]">
                            <span className="text-gray-400">Remember password? </span>
                            <button onClick= {login} className="text-blue-500 underline font-semibold">Log in</button>
                        </p>
                
                    </div>
                </FocusTrap>
            </Popup>
        </div>
    )
}


export default ForgotPassword;