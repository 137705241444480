import { Table, Row } from "../components/table";
import { useMutation, useQuery } from "@apollo/client";
import { GET_BUYER_LEASES, Lease } from "../queries/getBuyerLeasesQuery";
import { Link } from "react-router-dom";
import {
  CreateLeaseResponseMutationResponse,
  CREATE_LEASE_RESPONSE,
} from "../queries/respondToLease";
import Profile from "../components/profile";

const friendlyDate = (date: string) =>
  new Date(date).toLocaleDateString("en-us");

const lifetimeEarnings = (lease: Lease) =>
  lease.payments
    .reduce((accumulator, currentValue) => accumulator + currentValue.net, 0)
    .toFixed(2);

const receievedEarnings = (lease: Lease) =>
  lease.payments
    .filter((t) => t.status === "SUCCESS")
    .reduce((accumulator, currentValue) => accumulator + currentValue.net, 0)
    .toFixed(2);

export const BuyerLeases = () => {
  const { loading, error, data } = useQuery<{ purchasedLeases: Lease[] }>(
    GET_BUYER_LEASES
  );

  const leases = data?.purchasedLeases.filter((lease) =>
    ["APPROVED", "PENDING"].includes(lease.status)
  );

  console.log(data);

  return (
    <>
      <h1 className="font-bold text-2xl mb-6">Leases</h1>
      <div className="bg-white p-4 rounded-md">
        <Table
          columnNames={[
            "Status",
            "Lessor",
            "Listing",
            "Dates",
            "Lifetime Cost",
            "Paid",
          ]}
          loading={loading}
        >
          {leases?.map((lease) => (
            <Row
              values={[
                <Status lease={lease} />,
                <Profile name={lease.lessor.firstName} id={lease.lessor.id} />,
                lease.spotSummary.name,
                `${friendlyDate(lease.start)} - ${friendlyDate(lease.end)}`,
                <div>
                  ${lifetimeEarnings(lease)}
                  <br />
                  <Link to={`../transactions?id=${lease.id}`}>View schedule</Link>
                </div>,
                `$${receievedEarnings(lease)}`,
              ]}
            />
          ))}
        </Table>
      </div>
    </>
  );
};

const Status = ({ lease }: { lease: Lease }) => {
  const [respondToLease, { loading, error, data }] = useMutation<{
    createLeaseResponse: CreateLeaseResponseMutationResponse;
  }>(CREATE_LEASE_RESPONSE);

  return (
    <div>
      {lease.status === "APPROVED" ? "Confirmed" : "Requires Approval"}
      {lease.status === "PENDING" && (
        <div>
          <button
            className="bg-red-500 text-white rounded p-1 text-xs hover:bg-red-600"
            onClick={() =>
              respondToLease({
                variables: {
                  id: lease.id,
                  data: {
                    status: "CANCELLED",
                  },
                },
              })
            }
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};
