import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Ammenity from "../components/ammenity";
import {
  GET_SPOT,
  Spot as SpotType,
  getSpotSize,
  getSpotType,
} from "../queries/getSpotQuery";

import Map from "../components/map";

import PriceView from "../components/priceview";

import { AiFillCar } from "react-icons/ai";
import { FaFootballBall, FaParking, FaRestroom } from "react-icons/fa";
import { IoTicket } from "react-icons/io5";
import { MdOutlineGarage } from "react-icons/md";
import Section from "../components/section";
import { DatePickerForm } from "./datepickerform";

const CREATE_THREAD = gql`
  mutation CreateThread($userId: ID!, $message: String!) {
    createThread(userId: $userId, message: $message) {
      code
      success
      message
      sentMessage {
        id
        timestamp
        text
        type
        authorId
        threadId
      }
    }
  }
`;

const SPOT_DEFAULT_IMAGE = "/default.png";

export interface SpotProps {
  id: string;
  datepickerClassName?: string | undefined;
  start: Date | null;
  end: Date | null;
  onStartChange: (d: Date | null) => void;
  onEndChange: (d: Date | null) => void;
}

export const Spot = ({
  id,
  datepickerClassName,
  start,
  end,
  onStartChange,
  onEndChange,
}: SpotProps) => {
  const [messageText, setMessageText] = useState("");

  const { loading, error, data } = useQuery<{ spot: SpotType }>(GET_SPOT, {
    variables: {
      spotId: id,
    },
  });

  const [sendMessage] = useMutation<
    object,
    { userId: string; message: string }
  >(CREATE_THREAD, {
    onCompleted: () => {
      alert(
        "Your message has been sent. Go to the Messages page to view your conversation."
      );
    },
  });

  return (
    <div>
      <div>
        <img
          src={data?.spot.images?.[0]?.href ?? SPOT_DEFAULT_IMAGE}
          alt="Spot"
          className="max-h-56 w-full object-cover"
        />
      </div>
      {/* content area */}
      <div className="px-4 py-8">
        <h1 className="text-3xl font-semibold">{data?.spot.name}</h1>
        <p className="my-3">{data?.spot.description}</p>

        <div className={`my-8 ${datepickerClassName}`}>
          <DatePickerForm
            id={id}
            start={start}
            end={end}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
          />
        </div>

        {data?.spot.period.fullRange === true && (
          <div className="bg-yellow-400 px-3 py-2 rounded-lg mt-6">
            This spot's dates have been preselected and cannot be changed.
          </div>
        )}
        {/* <Section
          title="PRICING"
          rightAccessory={
            data?.spot.upfrontOnly ? (
              <div className="rounded-full text-xs bg-red-500 text-white px-3 py-1 font-semibold">
                Upfront Payment
              </div>
            ) : undefined
          }
        >
          <PriceView {...data?.spot.pricing} />
        </Section> */}
        <Section title="LOCATION">
          <Map lat={data?.spot.position.lat} lng={data?.spot.position.lng} />
          <p className="mt-4">
            <span className="font-semibold">Address:</span> {data?.spot.address}
          </p>
        </Section>
        <Section title="MESSAGES">
          <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Have a question?</h2>
            <form className="flex flex-col">
              <label htmlFor="message" className="sr-only">
                Send the seller a message
              </label>
              <input
                type="text"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className="bg-gray-100 rounded-lg p-4 mb-4"
                placeholder="Send the seller a message"
              />
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                onClick={(e) => {
                  sendMessage({
                    variables: {
                      userId: data?.spot.ownerId ?? "",
                      message: messageText,
                    },
                  });
                  e.preventDefault();
                }}
              >
                Send
              </button>
            </form>
          </div>
        </Section>
        <Section title="AMMENITIES">
          {data?.spot.carType != null && (
            <Ammenity
              icon={<AiFillCar size={24} />}
              title={`Max Vehicle Size: ${getSpotSize(
                data?.spot.carType ?? -1
              )}`}
            />
          )}
          {data?.spot.spotType != null && (
            <Ammenity
              icon={<FaParking size={24} />}
              title={`Spot Type: ${getSpotType(data?.spot.spotType ?? -1)}`}
            />
          )}
          {data?.spot.sheltered === true && (
            <Ammenity icon={<MdOutlineGarage size={24} />} title="Sheltered" />
          )}
          {data?.spot.parkingPass === true && (
            <Ammenity
              icon={<IoTicket size={24} />}
              title="Parking pass provided by seller"
            />
          )}
          {data?.spot.restroom === true && (
            <Ammenity
              icon={<FaRestroom size={24} />}
              title="Restrooms available"
            />
          )}
          {data?.spot.tailgating === true && (
            <Ammenity
              icon={<FaFootballBall size={24} />}
              title="Tailgating permitted"
            />
          )}
        </Section>
      </div>
      <div className="my-8" />
    </div>
  );
};
