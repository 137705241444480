import { on } from "events";
import Button from "./button";

export type Props = {
  onBack?: () => void;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  submitText?: string;
  submitDisabled?: boolean;
  submitLoading?: boolean;
};

const FormActions = ({
  onBack,
  onClick,
  submitText,
  submitDisabled,
  submitLoading,
}: Props) => {
  return (
    <div className="flex justify-end mt-[2vh]">
      {onBack && <Button text="Back" type="tertiary" />}
      <Button
        onClick={onClick}
        text={submitText ?? "Next"}
        disabled={submitDisabled}
        loading={submitLoading}
      />{" "}
    </div>
  );
};

export default FormActions;
