const awsmobile = {
  aws_cognito_identity_pool_id: process.env["REACT_APP_COGNITO_IDP_ID"],
  aws_cognito_region: process.env["REACT_APP_COGNITO_REGION"],
  aws_user_pools_id: process.env["REACT_APP_COGNITO_USER_POOL_ID"],
  aws_user_pools_web_client_id: process.env["REACT_APP_COGNITO_CLIENT_ID"],
  oauth: {
    domain: process.env["REACT_APP_COGNITO_DOMAIN"],
    scope: ["email", "profile"],
    redirectSignIn: process.env["REACT_APP_COGNITO_REDIRECT_SIGNIN"],
    redirectSignOut: process.env["REACT_APP_COGNITO_REDIRECT_SIGNOUT"],
    clientId: process.env["REACT_APP_COGNITO_CLIENT_ID"],
    responseType: "code",
  },
};

export default awsmobile;
