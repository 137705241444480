import axios from "axios";

export interface TokenEndpointResponse {
  idToken: string;
  expiration: Date;
  refreshToken: string;
}

export const tokenFromAuthorizationCode = async (code: string) => {
  console.log("CODE");
  console.log(code);
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("code", code);
  params.append("client_id", process.env["REACT_APP_COGNITO_CLIENT_ID"] ?? "");
  params.append(
    "redirect_uri",
    process.env["REACT_APP_SITE_URL"] + "/oauth-callback"
  );

  return exchangeToken(params);
};

export const tokenFromRefreshToken = async (token: string) => {
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", token);
  params.append("client_id", process.env["REACT_APP_COGNITO_CLIENT_ID"] ?? "");

  return exchangeToken(params);
};

export const exchangeToken = async (
  params: URLSearchParams
): Promise<TokenEndpointResponse> => {
  const response = await axios.post(
    "https://" + process.env["REACT_APP_COGNITO_DOMAIN"] + "/oauth2/token",
    params
  );

  const absExpirationDate = new Date(
    Date.now() + response.data.expires_in * 1000
  );

  return {
    idToken: response.data.id_token,
    refreshToken: response.data.refresh_token,
    expiration: absExpirationDate,
  };
};
