import { Link } from "react-router-dom";

export type SidebarItem = {
  name: string;
  to: string;
};

export type SidebarProps = {
  title: string;
  items: SidebarItem[];
};

export const Sidebar = ({ title, items }: SidebarProps) => {
  return (
    <div className="bg-white text-grey-600 w-64">
      <div className="p-6">
        <h1 className="text-2xl font-bold">{title}</h1>
      </div>
      <ul className="text-gray-700">
        {items.map((item) => (
          <Link to={item.to}>
            <li className="px-6 py-3 hover:bg-blue-200 cursor-pointer">
              {item.name}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};
