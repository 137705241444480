import React from "react";
import { TokenEndpointResponse } from "../helpers/CognitoConnector";

interface AuthData {
    loggedIn: boolean,
    loginSuccess: boolean,
    alreadyLoginPopupShown: boolean,
    loginPopupShown: boolean;
    forgotPasswordShown: boolean;
    createAccountShown: boolean;
    verifyCreateAccountShown: boolean;
    verifyChangePasswordShown : boolean,
    ChangePasswordShown : boolean;
    succesfullyChangedShown : boolean,
    succesfullyCreatedShown : boolean,
    email : string,
    name: string;
    code : string,
    scrollPosition : number,
    login: (data: TokenEndpointResponse) => void,
    logout: () => void,
    getIdToken: () => Promise<string | undefined>,
    getId: () => string | undefined,
    showLoginPopup: () => void,
    hideLoginPopup: () => void,
    showAlreadyLoginPopup: () => void,
    hideAlreadyLoginPopup: () => void,
    showForgotPassword: () => void,
    hideForgotPassword: () => void,
    loginFromForgotPassword: () => void,
    loginFromCreateAccount: () => void,
    showCreateAccount: () => void,
    hideCreateAccount: () => void,
    showVerifyCreateAccount: () => void,
    hideVerifyCreateAccount: () => void,
    createAccountFromVerify: () => void,
    showChangePassword: () => void,
    hideChangePassword: () => void,
    showSuccesfullyChanged: () => void,
    hideSuccesfullyChanged: () => void,
    showSuccessfullyCreated : () => void,
    hideSuccessfullyCreated : () => void,
    showVerifyChangePassword: () => void,
    hideVerifyChangePassword: () => void,
    forgotPasswordFromVerify: () => void,
    setEmail: (data : string) => void,
    setCode: (data : string) => void,
    setScrollPosition: (data : number) => void,
}

const defaultValue: AuthData = {
    loggedIn: false,
    loginSuccess: false,
    loginPopupShown: false,
    alreadyLoginPopupShown: false,
    forgotPasswordShown: false,
    createAccountShown: false,
    verifyCreateAccountShown: false,
    ChangePasswordShown: false,
    succesfullyChangedShown: false,
    succesfullyCreatedShown : false,
    verifyChangePasswordShown: false,
    email : '',
    name: '',
    code : '',
    scrollPosition : 0,
    login: () => {},
    logout: () => {},
    getIdToken: async () => { return undefined },
    getId: () => { return undefined },
    showLoginPopup: () => { },
    hideLoginPopup: () => { },
    showAlreadyLoginPopup: () => {},
    hideAlreadyLoginPopup: () => {},
    showForgotPassword: () => { },
    hideForgotPassword: () => { },
    loginFromForgotPassword: () => { },
    loginFromCreateAccount: () => {},
    showCreateAccount: () => { },
    hideCreateAccount: () => { },
    showVerifyCreateAccount: () => {},
    hideVerifyCreateAccount: () => {},
    createAccountFromVerify: () => {},
    showChangePassword: () => {},
    hideChangePassword: () => {},
    showSuccesfullyChanged: () => {},
    hideSuccesfullyChanged: () => {},
    showVerifyChangePassword: () => {},
    hideVerifyChangePassword: () => {},
    forgotPasswordFromVerify: () => {},
    setEmail : (data : string) => {},
    setCode: (data : string) => {},
    setScrollPosition: (data : number) => {},
    showSuccessfullyCreated : () => {},
    hideSuccessfullyCreated : () => {},
}

export const AuthContext = React.createContext(defaultValue);
