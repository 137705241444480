import { useId, forwardRef, ReactNode } from "react";
import { MdOutlineHeight } from "react-icons/md";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder? : string;
  width? : string;
  height? : string;
  onKeyDown? : React.KeyboardEventHandler<HTMLInputElement>;
  error? : boolean;
  stage? : string;
  type?:
    | "text"
    | "email"
    | "tel"
    | "url"
    | "search"
    | "password"
    | "number"
    | "date";
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;
  margins? : string;
  listing? : boolean;
  autocomplete? : string;
  validationMessage?: string;
  validationState?: "none" | "error" | "success";
  additional? : string;
}
/*
export interface Props {
  label: string;
  type?: "text" | "email" | "tel" | "url" | "search" | "password" | "number";
  name?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  autoComplete?: string;
  disabled?: boolean;
  form?: string;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: number;
  defaultValue?: string | number | readonly string[];
  value?: string | number | readonly string[];
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;
  dir?: string;
  list?: string;
  max?: string | number;
  min?: string | number;
  step?: string | number;
  validationMessage?: string;
  validationState?: "none" | "error" | "success";
}*/

export type Ref = HTMLInputElement;

export default forwardRef<Ref, Props>(function TextField(props, ref) {
  const id = useId();
  const {
    label,
    type = "text",
    placeholder, 
    disabled,
    readOnly,
    onKeyDown,
    width = "100%",
    height = "5vh",
    stage = "normal",
    error,
    leftAccessory,
    rightAccessory,
    margins = "normal",
    listing = false,
    additional = "",
    autocomplete = "on",
    validationMessage,
    validationState = validationMessage == null ? "none" : "error",
    ...inputProps
  } = props;

  return (
    <div  className={`field flex justify-start flex-col items-start
    ${stage === "normal" && "ml-4 sm:ml-12 mr-4 sm:mr-12" }
    ${stage === "first" && "ml-0 sm:ml-0 mr-1 sm:mr-2" }
    ${stage === "last" && "ml-1 sm:ml-2 mr-0 sm:mr-0" }
    ${margins === "less" && "mt-[5.5vh] sm:mt-[3.5vh]"}
    ${margins === "normal" && "mt-[7.3vh] sm:mt-[5.3vh]"}
    ${stage === "listing" && "ml-1 sm:ml-2 mr-1 sm:mr-2"}
    
    `}>
        <label
          htmlFor={`textfield-${id}`}
          className= "mb-1 font-poppins font-bold sm:font-semibold"
        >
          {label}
        </label>
      <div
        className={`w-[100%] flex rounded-xl sm:rounded-md border-2 sm:border transition-all items-center ${
          (validationState === "error" && error === undefined)|| error === true
            ? "border-red-500 focus-within:border-red-500 focus-within:border-[0.18vmin]" 
            : ""
        }
        ${
          (validationState === "none" && error === undefined)|| error === false
            ? "border-slate-400 focus-within:border-blue-500"
            : ""
        } ${disabled || readOnly ? "bg-gray-100" : "bg-gray-300"}`}
      >
        {leftAccessory && <Accessory>{leftAccessory}</Accessory>}
        <div className = {`w-[100%]`}>
          <input
            {...inputProps}
            type={type}
            aria-invalid = {{error} ? "true" : "false"}
            onKeyDown={onKeyDown}
            disabled={disabled}
            autoComplete= {autocomplete}
            readOnly={readOnly}
            ref={ref}
            placeholder={placeholder}
            id={`textfield-${id}`}
            className= {`w-full bg-gray-300 border border-gray-300 placeholder-gray-500 rounded-xl sm:rounded-md transition 
            duration-300 px-4 py-2 text-gray-800 font-poppins outline-none ${additional} `}
          />
        </div>
        {rightAccessory && <Accessory>{rightAccessory}</Accessory>}
      </div>
      {validationMessage && (
        <span 
          role = "alert"
          className={`mt-[0vh] font-poppins italic font-bold font-medium sm:font-normal text-sm text-right ml-auto
           ${((validationState === "error" && error === undefined) || error === true) && "text-red-500"}`}
        >
          {validationMessage}
        </span>
      )}
    </div>
  );
});

interface AccessoryProps {
  children: ReactNode;
}

const Accessory = ({ children }: AccessoryProps) => {
  return (
    <div className="p-2.5 px-3 flex items-center justify-center">
      {children}
    </div>
  );
};
