import { Sidebar } from "../components/sidebar";
import { GET_SELLER_LEASES, Lease } from "../queries/getSellerLeasesQuery";
import { useQuery } from "@apollo/client";
import { Table, Row } from "../components/table";
import { Outlet } from "react-router-dom";

export const Orders = () => {
  return (
    <div className="flex h-screen">
      <Sidebar
        title="Renter Portal"
        items={[
          { name: "Leases", to: "leases" },
          { name: "Transactions", to: "transactions" },
        ]}
      />
      <div className="bg-blue-100 flex-1">
        <div className="px-10 py-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
