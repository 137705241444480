const Sedan = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.932 83.5782C124.932 84.9082 100.327 85.9932 69.9824 85.9932C39.6724 85.9932 15.0674 84.9082 15.0674 83.5782C15.0674 82.2832 39.6724 81.1982 69.9824 81.1982C100.327 81.1982 124.932 82.2832 124.932 83.5782Z"
        fill="#67A9F1"
      />
      <path
        d="M26.6174 75.9832L30.2574 67.4082H107.257V77.1382L104.562 79.3082L101.972 77.3832H91.6124L88.3924 79.3432L86.8174 77.2432H50.1024L45.7274 79.3432L43.0674 77.5232H32.9524L29.5574 79.3432L26.6174 75.9832Z"
        fill="#0D1F27"
      />
      <path
        d="M115.972 71.3633V75.1083C115.972 77.1033 113.592 77.6283 113.592 77.6283L104.562 79.3083C104.737 78.6433 104.842 77.9783 104.842 77.2783C104.842 72.6583 101.097 68.9133 96.4774 68.9133C91.8574 68.9133 88.1124 72.6583 88.1124 77.2783C88.1124 77.9783 88.2174 78.6783 88.3924 79.3433H45.7274C45.9024 78.6783 46.0074 77.9783 46.0074 77.2783C46.0074 72.6583 42.2624 68.9133 37.6424 68.9133C33.0574 68.9133 29.3124 72.6583 29.3124 77.2783C29.3124 77.9783 29.4174 78.6783 29.5574 79.3433H27.7724H25.9524C25.0074 79.3433 24.2024 78.5733 24.2024 77.5933V72.8333C24.2024 71.9583 24.9024 71.2233 25.7774 71.1183C26.2324 68.8433 27.8774 65.3433 33.4424 64.7133L50.1374 63.4183C50.6274 62.9983 51.1291 62.59 51.6424 62.1933C54.2324 60.2333 56.9624 58.5183 59.8674 57.1183C62.2824 55.9283 64.8724 55.0183 67.5324 54.4933C70.7874 53.8283 74.1474 54.0383 77.4374 54.0383C81.9524 54.0733 86.6424 54.0383 91.0174 55.1583C94.4824 56.0683 97.9124 57.2583 101.167 58.8333C102.882 59.6383 104.562 60.5483 106.172 61.5633L111.142 61.4233C111.142 61.4233 115.062 61.4233 114.467 68.6333C116.217 68.9833 115.972 71.3633 115.972 71.3633Z"
        fill="#3C454A"
      />
      <path
        d="M114.467 68.6331H112.017C111.072 68.6331 110.267 67.8281 110.267 66.8831V66.8481C110.267 65.8681 111.072 65.0981 112.017 65.0981H114.327C114.502 66.0081 114.572 67.1631 114.467 68.6331Z"
        fill="#EE3837"
      />
      <path
        d="M26.8623 70.6284C26.8623 70.6284 27.5273 64.9584 36.0323 65.5184C36.0323 65.5184 32.4623 69.2284 26.8623 70.6284Z"
        fill="#0D1F27"
      />
      <path
        d="M27.4224 70.0331C27.4224 70.0331 27.9474 66.0081 34.4224 66.3931C34.4224 66.3931 31.6924 69.0531 27.4224 70.0331Z"
        fill="#FFDE17"
      />
      <path
        d="M24.2024 77.5934V72.8334C24.2024 72.7634 24.2141 72.6817 24.2374 72.5884H30.7474C29.8374 73.9184 29.3124 75.5284 29.3124 77.2784C29.3124 77.9784 29.4174 78.6784 29.5574 79.3434H27.7724H25.9524C25.0074 79.3434 24.2024 78.5734 24.2024 77.5934Z"
        fill="#2B353A"
      />
      <path
        d="M103.407 72.5881H113.172C113.172 72.5881 114.747 72.6231 115.972 72.0981V75.1081C115.972 77.1031 113.592 77.6281 113.592 77.6281L104.562 79.3081C104.737 78.6431 104.842 77.9781 104.842 77.2781C104.842 75.5281 104.282 73.9181 103.407 72.5881Z"
        fill="#2B353A"
      />
      <path
        d="M45.7275 79.3434C45.9025 78.6784 46.0075 77.9784 46.0075 77.2784C46.0075 75.5284 45.4825 73.9184 44.5725 72.5884H89.5475C88.6725 73.9184 88.1125 75.5284 88.1125 77.2784C88.1125 77.9784 88.2175 78.6784 88.3925 79.3434H45.7275Z"
        fill="#2B353A"
      />
      <path
        d="M44.3278 77.2783C44.3278 80.9533 41.3528 83.9633 37.6428 83.9633C33.9678 83.9633 30.9578 80.9533 30.9578 77.2783C30.9578 73.5683 33.9678 70.5933 37.6428 70.5933C41.3528 70.5933 44.3278 73.5683 44.3278 77.2783Z"
        fill="#2E363D"
      />
      <path
        d="M41.9128 77.2783C41.9128 79.6233 40.0228 81.5483 37.6428 81.5483C35.2978 81.5483 33.3728 79.6233 33.3728 77.2783C33.3728 74.8983 35.2978 73.0083 37.6428 73.0083C40.0228 73.0083 41.9128 74.8983 41.9128 77.2783Z"
        fill="#93A5A5"
      />
      <path
        d="M41.6679 78.7134L39.8479 78.1884L41.4929 79.0984C41.4695 79.1918 41.4229 79.2851 41.3529 79.3784L38.7629 77.9434C38.6695 78.1301 38.5179 78.2818 38.3079 78.3984L39.7779 80.9884C39.6845 81.0351 39.5912 81.0818 39.4979 81.1284L38.5529 79.4834L39.0779 81.3034C38.9845 81.3268 38.8912 81.3618 38.7979 81.4084L37.9929 78.5384C37.8762 78.5618 37.7595 78.5734 37.6429 78.5734C37.5262 78.5734 37.4212 78.5618 37.3279 78.5384L36.5229 81.4084C36.4295 81.3618 36.3362 81.3268 36.2429 81.3034L36.7329 79.4834L35.8229 81.1284C35.7295 81.0818 35.6362 81.0351 35.5429 80.9884L36.9779 78.3984C36.7912 78.2818 36.6395 78.1301 36.5229 77.9434L33.9329 79.3784C33.8862 79.2851 33.8395 79.1918 33.7929 79.0984L35.4379 78.1884L33.6179 78.7134C33.5945 78.5968 33.5712 78.4918 33.5479 78.3984L36.3829 77.5934C36.3595 77.5001 36.3479 77.3951 36.3479 77.2784C36.3479 77.1618 36.3595 77.0568 36.3829 76.9634L33.5479 76.1584C33.5712 76.0418 33.5945 75.9368 33.6179 75.8434L35.4379 76.3684L33.7929 75.4234C33.8395 75.3301 33.8862 75.2484 33.9329 75.1784L36.5229 76.6134C36.6395 76.4268 36.7912 76.2751 36.9779 76.1584L35.5429 73.5684C35.6362 73.5218 35.7295 73.4751 35.8229 73.4284L36.7329 75.0734L36.2429 73.2534C36.3362 73.2068 36.4295 73.1718 36.5229 73.1484L37.3279 76.0184C37.4212 75.9951 37.5262 75.9718 37.6429 75.9484C37.7595 75.9718 37.8762 75.9951 37.9929 76.0184L38.7979 73.1484C38.8912 73.1718 38.9845 73.2068 39.0779 73.2534L38.5529 75.0734L39.4979 73.4284C39.5912 73.4751 39.6845 73.5218 39.7779 73.5684L38.3079 76.1584C38.5179 76.2751 38.6695 76.4268 38.7629 76.6134L41.3529 75.1784C41.4229 75.2484 41.4695 75.3301 41.4929 75.4234L39.8479 76.3684L41.6679 75.8434C41.7145 75.9368 41.7495 76.0418 41.7729 76.1584L38.9029 76.9634C38.9495 77.0568 38.9729 77.1618 38.9729 77.2784C38.9729 77.3951 38.9495 77.5001 38.9029 77.5934L41.7729 78.3984C41.7495 78.4918 41.7145 78.5968 41.6679 78.7134Z"
        fill="#E2E5E7"
      />
      <path
        d="M103.162 77.2783C103.162 80.9533 100.187 83.9633 96.4775 83.9633C92.8025 83.9633 89.7925 80.9533 89.7925 77.2783C89.7925 73.5683 92.8025 70.5933 96.4775 70.5933C100.187 70.5933 103.162 73.5683 103.162 77.2783Z"
        fill="#2E363D"
      />
      <path
        d="M100.748 77.2783C100.748 79.6233 98.8575 81.5483 96.4775 81.5483C94.1325 81.5483 92.2075 79.6233 92.2075 77.2783C92.2075 74.8983 94.1325 73.0083 96.4775 73.0083C98.8575 73.0083 100.748 74.8983 100.748 77.2783Z"
        fill="#93A5A5"
      />
      <path
        d="M100.503 78.7134L98.6826 78.1884L100.328 79.0984C100.304 79.1918 100.258 79.2851 100.188 79.3784L97.5976 77.9434C97.5042 78.1301 97.3526 78.2818 97.1426 78.3984L98.6126 80.9884C98.5192 81.0351 98.4259 81.0818 98.3326 81.1284L97.3876 79.4834L97.9126 81.3034C97.8192 81.3268 97.7259 81.3618 97.6326 81.4084L96.8276 78.5384C96.7109 78.5618 96.5942 78.5734 96.4776 78.5734C96.3609 78.5734 96.2559 78.5618 96.1626 78.5384L95.3576 81.4084C95.2642 81.3618 95.1709 81.3268 95.0776 81.3034L95.5676 79.4834L94.6576 81.1284C94.5642 81.0818 94.4709 81.0351 94.3776 80.9884L95.8126 78.3984C95.6259 78.2818 95.4742 78.1301 95.3576 77.9434L92.7676 79.3784C92.7209 79.2851 92.6742 79.1918 92.6276 79.0984L94.2726 78.1884L92.4526 78.7134C92.4292 78.5968 92.4059 78.4918 92.3826 78.3984L95.2176 77.5934C95.1942 77.5001 95.1826 77.3951 95.1826 77.2784C95.1826 77.1618 95.1942 77.0568 95.2176 76.9634L92.3826 76.1584C92.4059 76.0418 92.4292 75.9368 92.4526 75.8434L94.2726 76.3684L92.6276 75.4234C92.6742 75.3301 92.7209 75.2484 92.7676 75.1784L95.3576 76.6134C95.4742 76.4268 95.6259 76.2751 95.8126 76.1584L94.3776 73.5684C94.4709 73.5218 94.5642 73.4751 94.6576 73.4284L95.5676 75.0734L95.0776 73.2534C95.1709 73.2068 95.2642 73.1718 95.3576 73.1484L96.1626 76.0184C96.2559 75.9951 96.3609 75.9718 96.4776 75.9484C96.5942 75.9718 96.7109 75.9951 96.8276 76.0184L97.6326 73.1484C97.7259 73.1718 97.8192 73.2068 97.9126 73.2534L97.3876 75.0734L98.3326 73.4284C98.4259 73.4751 98.5192 73.5218 98.6126 73.5684L97.1426 76.1584C97.3526 76.2751 97.5042 76.4268 97.5976 76.6134L100.188 75.1784C100.258 75.2484 100.304 75.3301 100.328 75.4234L98.6826 76.3684L100.503 75.8434C100.549 75.9368 100.584 76.0418 100.608 76.1584L97.7376 76.9634C97.7842 77.0568 97.8076 77.1618 97.8076 77.2784C97.8076 77.3951 97.7842 77.5001 97.7376 77.5934L100.608 78.3984C100.584 78.4918 100.549 78.5968 100.503 78.7134Z"
        fill="#E2E5E7"
      />
      <path
        d="M51.6775 77.2434L82.8625 75.8784C82.8625 75.8784 84.6825 75.7034 85.6275 73.5684C85.6275 73.5684 85.9775 76.5784 81.9175 76.6484L51.6775 77.2434Z"
        fill="#3C454A"
      />
      <path
        d="M27.5624 75.6684C27.5624 76.3684 27.0024 76.9284 26.3024 76.9284H24.2024V74.4434H26.3024C27.0024 74.4434 27.5624 75.0034 27.5624 75.6684Z"
        fill="#182830"
      />
      <path
        d="M50.6273 64.0485L95.0423 62.9985C95.0423 62.9985 93.1523 65.2035 93.0473 66.6735C93.0473 66.6735 89.2673 68.3885 87.8673 72.0635L50.1373 72.0285C50.1373 72.0285 50.0673 67.7235 50.6273 64.0485Z"
        fill="url(#paint0_linear_51_5231)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.0823 78.0831H50.6273V78.0481C49.1923 71.2581 50.5923 64.1181 50.6273 64.0481L50.6623 64.0131C51.1873 63.5581 51.6773 63.1731 52.1323 62.8231C54.7923 60.8281 57.4873 59.1481 60.2173 57.8181C62.7723 56.5931 65.2923 55.7181 67.6723 55.2631C69.7023 54.8781 71.7323 54.8081 73.4823 54.8081C74.1823 54.8081 74.8823 54.8081 75.5473 54.8431H75.6523C76.259 54.8431 76.854 54.8431 77.4373 54.8431H77.8223C82.1623 54.8431 86.6423 54.8781 90.8073 55.9281C92.4873 56.3481 94.1323 56.8731 95.7423 57.4331C95.8123 57.4681 97.3523 57.9581 97.6323 59.1831C97.8073 60.0581 97.3523 61.0381 96.1973 62.1231C96.1623 62.1581 93.6073 63.9781 93.0823 66.6381V66.6731H93.0473C92.9773 66.7081 87.4123 68.8781 86.0823 78.0481V78.0831ZM50.7673 77.9081H85.9423C87.2723 69.1231 92.4523 66.7431 92.9423 66.5331C93.5023 63.8381 96.0923 62.0181 96.0923 61.9831C97.2123 60.9331 97.6673 60.0231 97.4923 59.1831C97.2123 58.0631 95.7073 57.5381 95.7073 57.5381C94.0973 56.9781 92.4523 56.4881 90.7723 56.0331C86.6423 54.9831 82.1623 54.9831 77.8223 54.9831H77.4723C76.8656 54.9598 76.259 54.9481 75.6523 54.9481H75.5823C74.8823 54.9481 74.1823 54.9481 73.4823 54.9481C71.7673 54.9481 69.7373 54.9831 67.7073 55.3681C65.3273 55.8581 62.8073 56.6981 60.2873 57.9581C57.5923 59.2531 54.8623 60.9331 52.2373 62.9281C51.7823 63.2781 51.2923 63.6631 50.7673 64.0831C50.6973 64.5731 49.4023 71.3981 50.7673 77.9081Z"
        fill="#1A272D"
      />
      <path
        d="M73.9024 78.0133C73.7974 69.0533 75.3374 62.7533 75.3374 62.7183L75.4774 62.7533C75.4774 62.7883 73.9373 69.0883 74.0423 78.0133H73.9024Z"
        fill="#1A272D"
      />
      <path
        d="M92.6973 62.2984L52.7273 63.5934C55.3173 61.5984 57.9773 59.9534 60.6023 58.6934C63.0873 57.5034 65.5373 56.6634 67.8823 56.2084C69.8073 55.8234 71.8023 55.7534 73.4823 55.7534C74.1823 55.7534 74.8706 55.7651 75.5473 55.7884C76.1773 55.7884 76.8073 55.7884 77.4373 55.7884H77.8223C82.0923 55.8234 86.5373 55.8234 90.5973 56.8384C92.1606 57.2584 93.689 57.7251 95.1823 58.2384C95.1823 58.2384 96.8623 58.9734 95.6023 60.8284C95.6023 60.8284 94.9023 62.1934 92.6973 62.2984Z"
        fill="#0D1F27"
      />
      <path
        d="M58.4325 63.5583C58.4325 64.6433 57.0325 65.5183 55.3175 65.5183C53.6375 65.5183 53.3225 64.6433 53.3225 63.5583C53.3225 62.5083 53.6375 61.6333 55.3175 61.6333C57.0325 61.6333 58.4325 62.5083 58.4325 63.5583Z"
        fill="url(#paint1_radial_51_5231)"
      />
      <path
        d="M84.5423 62.5434L74.7073 62.8934L67.9873 56.1734C69.8773 55.8234 71.8373 55.7534 73.4823 55.7534C74.1823 55.7534 74.8706 55.7651 75.5473 55.7884C76.1773 55.7884 76.8073 55.7884 77.4373 55.7884H77.7873L84.5423 62.5434Z"
        fill="#223139"
      />
      <path
        d="M75.8624 55.7881C76.3991 55.7881 76.9241 55.7881 77.4374 55.7881H77.8224C78.0557 55.7881 78.2774 55.7881 78.4874 55.7881L77.2624 62.7881L73.5874 62.9281L75.8624 55.7881Z"
        fill="#2B353A"
      />
      <path
        d="M49.1573 71.1185C45.8323 64.4335 37.6423 65.2035 37.6423 65.2035L49.6473 64.1885C48.8423 69.0535 49.1573 71.1185 49.1573 71.1185Z"
        fill="url(#paint2_linear_51_5231)"
      />
      <path
        d="M104.492 68.8781C107.467 72.2031 113.242 70.9781 113.242 70.9781C114.362 70.9781 114.887 69.9281 114.887 69.9281C115.132 71.5731 113.417 71.4681 113.417 71.4681H105.472C101.132 64.5381 93.8875 66.6381 93.8875 66.6381C99.8025 63.9431 104.492 68.8781 104.492 68.8781Z"
        fill="url(#paint3_linear_51_5231)"
      />
      <path
        d="M98.4374 58.7632C101.342 59.7082 104.632 61.9482 104.632 61.9482L97.3174 62.2282C99.2774 60.7232 98.4374 58.7632 98.4374 58.7632Z"
        fill="url(#paint4_linear_51_5231)"
      />
      <path
        d="M46.7425 73.6032C44.0125 68.2132 39.1475 67.5132 39.1475 67.5132C45.3425 67.7932 47.8625 73.6382 47.8625 73.6382L46.7425 73.6032Z"
        fill="url(#paint5_linear_51_5231)"
      />
      <path
        d="M72.8524 66.3583H69.2824C68.8624 66.3583 68.5474 66.0433 68.5474 65.6233C68.5474 65.2383 68.8624 64.9233 69.2824 64.9233H72.8524C73.2374 64.9233 73.5874 65.2383 73.5874 65.6233C73.5874 66.0433 73.2374 66.3583 72.8524 66.3583Z"
        fill="#323C41"
      />
      <path
        d="M73.5524 65.6582C73.5524 66.0432 73.2374 66.3582 72.8524 66.3582H69.2824C68.8974 66.3582 68.5824 66.0432 68.5474 65.6582H73.5524Z"
        fill="#1A272D"
      />
      <path
        d="M91.2274 65.7983H87.6224C87.2374 65.7983 86.9224 65.4833 86.9224 65.0633C86.9224 64.6783 87.2374 64.3633 87.6224 64.3633H91.2274C91.6124 64.3633 91.9274 64.6783 91.9274 65.0633C91.9274 65.4833 91.6124 65.7983 91.2274 65.7983Z"
        fill="#323C41"
      />
      <path
        d="M91.9274 65.0981C91.9274 65.4831 91.6124 65.7981 91.2274 65.7981H87.6224C87.2374 65.7981 86.9574 65.4831 86.9224 65.0981H91.9274Z"
        fill="#1A272D"
      />
      <path
        d="M96.1274 63.0681L111.247 62.8931C111.247 62.8931 98.1924 63.4181 94.2024 65.7281C94.2024 65.7281 94.7274 64.1181 96.1274 63.0681Z"
        fill="url(#paint6_linear_51_5231)"
      />
      <path
        d="M37.5723 67.7582C32.9523 67.9682 29.9773 71.8882 29.9773 71.8882H28.7173C33.1973 66.6732 37.5723 67.7582 37.5723 67.7582Z"
        fill="url(#paint7_linear_51_5231)"
      />
      <path
        d="M96.5824 68.0385C92.2424 68.1435 89.4074 71.7135 89.4074 71.7135H88.2524C92.4874 66.9535 96.5824 68.0385 96.5824 68.0385Z"
        fill="url(#paint8_linear_51_5231)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_51_5231"
          x1="73.4823"
          y1="62.9285"
          x2="73.4823"
          y2="68.6685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6166" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_51_5231"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56.5075 62.8933) scale(3.36 3.36)"
        >
          <stop stop-color="#5C6166" />
          <stop offset="1" stop-color="#2A353A" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_51_5231"
          x1="42.8223"
          y1="62.2635"
          x2="49.2273"
          y2="66.6385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2B353A" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_51_5231"
          x1="100.887"
          y1="65.8331"
          x2="113.732"
          y2="65.8331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2B353A" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_51_5231"
          x1="101.797"
          y1="59.3582"
          x2="101.797"
          y2="61.6682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2B353A" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_51_5231"
          x1="44.4675"
          y1="76.0182"
          x2="37.7125"
          y2="70.7682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2B353A" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_51_5231"
          x1="95.3574"
          y1="62.8931"
          x2="106.592"
          y2="62.8931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6166" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_51_5231"
          x1="36.6273"
          y1="70.6632"
          x2="32.1823"
          y2="73.3582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6166" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_51_5231"
          x1="95.5324"
          y1="70.6635"
          x2="91.4024"
          y2="73.0435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6166" />
          <stop offset="1" stop-color="#3C454A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Sedan;
