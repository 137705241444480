import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export type ChatThreadItemProps = {
  id: string;
  avatar: string;
  username: string;
  message: string;
  time: string;
};

const ChatThreadItem = ({
  id,
  avatar,
  username,
  message,
  time,
}: ChatThreadItemProps) => {
  return (
    <Link to={id}>
      <div className="flex items-center border-b border-gray-200 py-4 px-4 hover:bg-gray-100">
        <img className="h-10 w-10 rounded-full" src={avatar} alt="avatar" />
        <div className="ml-4">
          <h4 className="text-lg font-medium text-gray-900">{username}</h4>
          <p className="text-sm font-normal text-gray-500">{message}</p>
        </div>
        <div className="ml-auto">
          <p className="text-sm font-normal text-gray-500">{time}</p>
        </div>
      </div>
    </Link>
  );
};

const GET_THREADS = gql`
  query GET_THREADS {
    threads {
      threads {
        id
        title
        members {
          id
          name
          profilePicture
        }
        lastMessage {
          id
          timestamp
          text
          type
          authorId
          threadId
        }
      }
    }
  }
`;

interface Member {
  id: string;
  name: string;
  profilePicture: string;
}

interface Thread {
  id: string;
  title: string;
  members: Member[];
  lastMessage?: {
    timestamp: string;
    text: string;
  };
}

interface ThreadList {
  threads: Thread[];
}

export const ListOfThreads = ({ threads }: { threads: Thread[] }) => {
  const auth = useContext(AuthContext);

  return (
    <div>
      {threads.map((thread) => {
        let recp: Member | undefined;
        thread.members.forEach((m) => {
          if (m.id !== auth.getId()) recp = m;
        });

        if (recp == null) return <></>;

        return (
          <ChatThreadItem
            id={thread.id}
            avatar={recp.profilePicture}
            username={thread.title}
            message={thread.lastMessage?.text ?? "No message has been sent yet"}
            time={
              thread.lastMessage != null
                ? new Date(thread.lastMessage.timestamp).toLocaleDateString(
                    undefined,
                    { hour: "numeric", minute: "numeric", hour12: true }
                  )
                : ""
            }
          />
        );
      })}
    </div>
  );
};

export const Messages = () => {
  const { loading, error, data } = useQuery<{ threads: ThreadList }>(
    GET_THREADS
  );

  const threads = data?.threads.threads;

  if (error != null) return <p>{error.message}</p>;
  if (threads == null) return <p>Loading</p>;

  return (
    <div className="mx-auto max-w-3xl">
      <h1 className="text-3xl mt-7 font-bold">Messages</h1>
      <div className="lg:bg-white lg:drop-shadow-md lg:my-5 lg:rounded-xl lg:overflow-hidden">
        <ListOfThreads threads={threads} />
      </div>
    </div>
  );
};
