import { gql } from "@apollo/client";

export const GET_SELLER_LEASES = gql`
  query LeaseDetailsQuery {
    soldLeases {
      lessee {
        id
        firstName
      }
      end
      start
      status
      spotSummary {
        name
      }
      payouts {
        amount
        date
        fee
        net
        status
      }
      id
    }
  }
`;

export interface Lease {
  lessee: {
    id: string;
    firstName: string;
  };
  end: string;
  start: string;
  status: string;
  spotSummary: {
    name: string;
  };
  payouts: [
    {
      amount: number;
      date: string;
      fee: number;
      net: number;
      status: string;
    }
  ];
  id: string;
}
