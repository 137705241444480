const House = () => {
  return (
    <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M79.074 85.1952C76.2145 84.3307 73.754 82.2027 72.69 79.2102C71.4265 75.9518 72.0915 72.4273 74.153 69.8338C71.892 63.5828 74.8845 56.6668 81.0025 54.2063C82.4655 53.6743 83.9285 53.4083 85.3915 53.4083C83.596 47.2904 86.5885 40.7069 92.507 38.3129C98.7579 35.8524 105.873 38.9779 108.467 45.4284C109.531 48.0219 109.597 50.8148 108.932 53.2753C108.977 53.3197 108.999 53.3418 108.999 53.3418C114.718 52.0783 120.703 55.2038 123.03 60.9893C123.385 61.876 123.629 62.7626 123.762 63.6493C125.557 64.9793 126.289 67.5063 125.358 69.7008C124.826 70.8978 123.961 71.7623 122.897 72.2943C124.028 77.0157 121.501 81.9367 116.912 83.6657C112.723 85.1952 108.267 83.6657 105.74 80.2742C104.011 81.6707 101.75 82.2692 99.4229 81.8037C98.1594 84.3307 95.965 86.4587 93.172 87.5892C91.2435 88.3872 89.182 88.5867 87.2535 88.3872C86.8545 89.5177 85.9235 90.5817 84.66 91.0472C82.3325 91.8452 79.8055 90.7147 79.0075 88.3872C78.6085 87.3232 78.675 86.1927 79.074 85.1952Z"
        fill="#8AA451"
      />
      <path
        d="M98.0265 89.5178C97.96 88.3208 97.96 87.3233 98.093 85.7938C94.635 84.5968 92.64 75.0208 92.64 75.0208C95.2335 80.6068 97.295 83.4663 98.4255 83.7988C98.492 82.6018 98.6915 79.7423 98.758 78.5453C98.8245 77.4148 98.891 76.4173 99.024 75.6193C99.2235 74.2893 98.5585 68.3709 99.157 67.8389C99.157 67.8389 100.221 76.4838 100.686 75.4863C102.149 72.4938 102.881 72.0284 104.61 69.5014C104.809 69.2354 100.686 77.9468 100.753 78.4123C100.886 80.4073 101.152 83.2668 101.418 87.3898C102.681 86.6583 104.676 85.2618 105.74 84.3308C105.009 81.8038 107.403 76.7498 107.403 76.7498C107.336 79.4763 106.272 83.0673 106.804 83.3998C107.868 82.1363 113.122 78.2128 114.385 76.5503C114.385 76.5503 107.004 86.2593 101.617 90.0498C101.883 93.1753 101.95 98.4288 102.083 99.4262C102.349 101.554 102.748 103.948 103.213 105.81H96.896C97.029 104.879 97.2285 104.015 97.295 103.017C97.295 101.089 97.3615 94.2393 97.561 92.9093C89.7805 88.9858 83.197 79.6093 83.197 79.6093C88.783 85.1288 95.699 89.3848 98.0265 89.5178Z"
        fill="#493B34"
      />
      <path
        opacity="0.5"
        d="M95.4995 41.1724C89.7805 41.9039 88.0515 43.6994 85.2585 46.6919C86.1895 42.9679 88.7165 39.8424 92.507 38.3129C98.758 35.8524 105.873 38.9779 108.467 45.4284C109.531 48.0219 109.597 50.8148 108.932 53.2753C108.977 53.3197 108.999 53.3418 108.999 53.3418C114.718 52.0783 120.703 55.2038 123.03 60.9893C123.252 61.5656 123.429 62.142 123.562 62.7183C115.848 53.8738 100.287 59.4598 103.28 57.1988C109.73 52.2113 106.339 39.7759 95.4995 41.1724Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M87.985 55.9352C85.9235 56.1347 82.0666 56.6667 78.9411 58.0632C77.8106 58.5952 75.2836 60.2577 74.4856 60.5902C75.6826 57.7972 77.9436 55.4697 81.0026 54.2062C82.4656 53.6742 83.9286 53.4082 85.3916 53.4082C85.3916 53.4082 91.5095 55.6027 87.985 55.9352Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M111.459 59.9917C112.39 59.9917 113.188 60.7232 113.188 61.6542C113.188 62.5852 112.39 63.3167 111.459 63.3167C110.528 63.3167 109.797 62.5852 109.797 61.6542C109.797 60.7232 110.528 59.9917 111.459 59.9917Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M115.05 62.5854C116.513 62.5854 117.777 63.7824 117.777 65.3119C117.777 66.8414 116.513 68.0384 115.05 68.0384C113.521 68.0384 112.257 66.8414 112.257 65.3119C112.257 63.7824 113.521 62.5854 115.05 62.5854Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M115.05 59.7925C115.449 59.7925 115.782 60.125 115.782 60.5905C115.782 60.9895 115.449 61.322 115.05 61.322C114.585 61.322 114.252 60.9895 114.252 60.5905C114.252 60.125 114.585 59.7925 115.05 59.7925Z"
        fill="#E5E884"
      />
      <g opacity="0.5">
        <path
          d="M72.69 79.2105C71.4265 75.952 72.0915 72.4275 74.153 69.834C76.946 70.898 79.34 69.967 79.34 69.967C79.34 69.967 77.877 78.4125 86.3225 80.4739C94.0364 82.4024 98.8244 72.0285 98.8244 72.0285C98.8244 72.0285 105.607 76.2845 114.319 75.553C118.508 75.154 118.575 70.5655 120.37 67.839C122.166 65.1125 121.567 70.0335 123.695 69.302C124.493 69.036 125.158 68.7922 125.69 68.5705C125.602 68.9695 125.491 69.3463 125.358 69.701C124.826 70.898 123.961 71.7625 122.897 72.2945C124.028 77.016 121.501 81.9369 116.912 83.6659C112.723 85.1954 108.267 83.6659 105.74 80.2744C104.011 81.6709 101.75 82.2694 99.4229 81.8039C98.1594 84.3309 95.965 86.4589 93.172 87.5894C91.2435 88.3874 89.182 88.5869 87.2535 88.3874C86.8545 89.5179 85.9235 90.5819 84.66 91.0474C82.3325 91.8454 79.8055 90.7149 79.0075 88.3874C78.6085 87.3234 78.675 86.1929 79.074 85.1954C76.2145 84.3309 73.754 82.2029 72.6235 79.2105H72.69Z"
          fill="#385A27"
        />
        <path
          d="M83.1971 69.5679C84.6601 69.5679 85.8571 70.7649 85.8571 72.2279C85.8571 73.6909 84.6601 74.8879 83.1971 74.8879C81.7341 74.8879 80.5371 73.6909 80.5371 72.2279C80.5371 70.7649 81.7341 69.5679 83.1971 69.5679Z"
          fill="#385A27"
        />
        <path
          d="M79.8056 63.9819C80.7366 63.9819 81.5346 64.7799 81.5346 65.7109C81.5346 66.5754 80.7366 67.3734 79.8056 67.3734C78.9411 67.3734 78.1431 66.5754 78.1431 65.7109C78.1431 64.7799 78.9411 63.9819 79.8056 63.9819Z"
          fill="#385A27"
        />
        <path
          d="M82.7317 66.7085C83.3967 66.7085 83.9951 67.307 83.9951 67.972C83.9951 68.637 83.3967 69.2355 82.7317 69.2355C82.0667 69.2355 81.5347 68.637 81.5347 67.972C81.5347 67.307 82.0667 66.7085 82.7317 66.7085Z"
          fill="#385A27"
        />
        <path
          d="M98.8246 43.1011C99.7556 43.1011 100.554 43.8991 100.554 44.8966C100.554 45.8276 99.7556 46.6256 98.8246 46.6256C97.8271 46.6256 97.0291 45.8276 97.0291 44.8966C97.0291 43.8991 97.8271 43.1011 98.8246 43.1011Z"
          fill="#385A27"
        />
        <path
          d="M102.083 47.0244C102.881 47.0244 103.546 47.6894 103.546 48.4209C103.546 49.2189 102.881 49.8839 102.083 49.8839C101.352 49.8839 100.687 49.2189 100.687 48.4209C100.687 47.6894 101.352 47.0244 102.083 47.0244Z"
          fill="#385A27"
        />
      </g>
      <path
        opacity="0.5"
        d="M101.019 91.3131V90.1826L101.618 90.3156C101.884 93.5076 101.95 98.4286 102.083 99.4261C102.349 101.554 102.748 103.948 103.214 105.81H101.817L101.019 101.355V91.3131Z"
        fill="#A86F41"
      />
      <path
        d="M109.797 78.2793C110.728 78.2793 111.46 79.0773 111.46 80.0083C111.46 80.8728 110.728 81.6708 109.797 81.6708C108.866 81.6708 108.135 80.8728 108.135 80.0083C108.135 79.0773 108.866 78.2793 109.797 78.2793Z"
        fill="#87AD54"
      />
      <path
        d="M107.669 76.2178C108.267 76.2178 108.733 76.6833 108.733 77.2818C108.733 77.8803 108.267 78.3458 107.669 78.3458C107.07 78.3458 106.605 77.8803 106.605 77.2818C106.605 76.6833 107.07 76.2178 107.669 76.2178Z"
        fill="#87AD54"
      />
      <path
        d="M88.3174 105.743H17.2957V71.8286H88.3174V105.743Z"
        fill="#F2F0D1"
      />
      <path
        d="M88.3174 105.744H17.2957V102.153H88.3174V105.744Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 99.6921H17.2957V98.8276H88.3174V99.6921Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 97.1654H17.2957V96.2344H88.3174V97.1654Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 94.5716H17.2957V93.6406H88.3174V94.5716Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 91.9784H17.2957V91.0474H88.3174V91.9784Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 89.3846H17.2957V88.4536H88.3174V89.3846Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 86.7913H17.2957V85.9268H88.3174V86.7913Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 84.198H17.2957V83.3335H88.3174V84.198Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 81.6707H17.2957V80.7397H88.3174V81.6707Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 79.0775H17.2957V78.1465H88.3174V79.0775Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 76.4837H17.2957V75.5527H88.3174V76.4837Z"
        fill="#D6D5BB"
      />
      <path
        d="M88.3174 74.3558H17.2957V71.6958H88.3174V74.3558Z"
        fill="#D6D5BB"
      />
      <path
        d="M91.3763 69.3018H14.2366L30.3295 38.0469H75.2834L91.3763 69.3018Z"
        fill="#F58D6C"
      />
      <path
        d="M91.3763 71.8288H14.2366V69.3018H91.3763V71.8288Z"
        fill="#CC7059"
      />
      <path
        d="M58.5254 102.153H47.0874V83.2002H58.5254V102.153Z"
        fill="#716558"
      />
      <path
        d="M57.7941 101.488H47.8191V83.9316H57.7941V101.488Z"
        fill="#1C405D"
      />
      <path
        d="M28.9996 95.4362H25.2756V81.4048H28.9996V95.4362Z"
        fill="#716558"
      />
      <path
        d="M40.1051 95.4362H36.3811V81.4048H40.1051V95.4362Z"
        fill="#716558"
      />
      <path
        d="M69.2318 95.4362H65.5078V81.4048H69.2318V95.4362Z"
        fill="#716558"
      />
      <path
        d="M80.3373 95.4362H76.6133V81.4048H80.3373V95.4362Z"
        fill="#716558"
      />
      <path
        d="M36.381 95.4362H28.9995V81.4048H36.381V95.4362Z"
        fill="url(#paint0_linear_56_2249)"
      />
      <path
        d="M76.6134 95.4362H69.2319V81.4048H76.6134V95.4362Z"
        fill="url(#paint1_linear_56_2249)"
      />
      <path
        d="M60.2544 104.015H45.3584V102.153H60.2544V104.015Z"
        fill="white"
      />
      <path
        d="M60.2544 83.2003H45.3584V81.4048H60.2544V83.2003Z"
        fill="#D6D5BB"
      />
      <path
        d="M60.2544 104.014H45.3584V103.083H60.2544V104.014Z"
        fill="#A6A8AB"
      />
      <path d="M62.0499 105.81H43.563V104.015H62.0499V105.81Z" fill="white" />
      <path d="M62.0499 105.81H43.563V104.879H62.0499V105.81Z" fill="#A6A8AB" />
      <path
        d="M49.7473 93.242C49.7473 93.508 49.5478 93.7075 49.2818 93.7075C48.9493 93.7075 48.7498 93.508 48.7498 93.242C48.7498 92.9095 48.9493 92.71 49.2818 92.71C49.5478 92.71 49.7473 92.9095 49.7473 93.242Z"
        fill="#A6A8AB"
      />
      <path
        opacity="0.1"
        d="M88.3174 105.743H17.2957L88.3174 71.4961V105.743Z"
        fill="#020051"
      />
      <path d="M57.395 59.1939H48.218V34.1899H57.395V59.1939Z" fill="#CC7059" />
      <defs>
        <linearGradient
          id="paint0_linear_56_2249"
          x1="37.5115"
          y1="72.8928"
          x2="48.2179"
          y2="83.5993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89C5F2" />
          <stop offset="1" stop-color="#002F64" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_56_2249"
          x1="77.7439"
          y1="72.8928"
          x2="88.4504"
          y2="83.5993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89C5F2" />
          <stop offset="1" stop-color="#002F64" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default House;
