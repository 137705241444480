import React from "react";

export type Props = {
  height: string;
  children: React.ReactNode;
};

const Popup = ({ height, children }: Props) => {
  const isMobile = window.screen.width < 768; // Set the breakpoint as per your requirement

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div
        className={`w-full sm:w-[88vw] md:w-[66vw] lg:w-[44.38vw] h-full sm:h-auto flex flex-col max-h-[100%] overflow-y-auto 
        rounded-[0px] md:rounded-[30px] bg-white fixed top-[50%] 
          sm:top-[54%] left-[50%] transform translate-x-[-50%] 
          translate-y-[-50%] p-0 px-[30px] pb-[30px] text-center text-black`}
      >
        {children}
      </div>
    </div>
  );
};

export default Popup;
