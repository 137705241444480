import { useState } from "react";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import Button from "./button";
import { GetInvoiceQuery } from "../__generated__/graphql";

interface UnitTranslatorType {
  [key: string]: string;
}

const UnitTranslator: UnitTranslatorType = {
  monthly: "months",
  weekly: "weeks",
  daily: "days",
  lease: "lease",
};

interface InvoiceProps {
  start: Date;
  end: Date;
  spotId: string;
}

const GET_INVOICE =
  gql(`query GetInvoiceData($spotId: String!, $start: String!, $end: String!) {
    getInvoice(spotId: $spotId, start: $start, end: $end) {
      spotId
      rentalPeriod {
        start
        end
      }
      rentalLength {
        months
        days
        totalDays
      }
      subscription
      payments {
        due
        subtotal
        fees
        total
      }
      summary {
        price
        quantity
        unit
      }
      token
    }
  }`);

export function Invoice({ start, end, spotId }: InvoiceProps) {
  const [expanded, setExpanded] = useState(false);

  const { loading, error, data } = useQuery(GET_INVOICE, {
    variables: {
      spotId: spotId,
      start: start.toISOString(),
      end: end.toISOString(),
    }
  });

  if(loading || !data?.getInvoice) return null;

  const {
    summary: { price, quantity, unit },
    subscription,
    payments,
  } = data.getInvoice!;

  const subtotal =
    (payments!.reduce((p, v) => p + v.subtotal * 100, 0) | 0) / 100;
  const fees = (payments!.reduce((p, v) => p + v.fees * 100, 0) | 0) / 100;
  const total = (payments!.reduce((p, v) => p + v.total * 100, 0) | 0) / 100;

  if (!subscription) {
    return (
      <div>
        <LineItem
          left={
            <p>
              ${price} x {quantity.toFixed(2)} {UnitTranslator[unit]}
            </p>
          }
          right={<p>${subtotal}</p>}
        />
        <LineItem left={<p>Service Fee</p>} right={<p>${fees.toFixed(2)}</p>} />
        <LineItem left={<p>Total</p>} right={<p>${total.toFixed(2)}</p>} />
      </div>
    );
  } else {
    return (
      <div>
        <LineItem
          left={<p>Monthly Rent</p>}
          right={<p>${payments?.[0].subtotal.toFixed(2)}</p>}
        />
        <LineItem
          left={<p>Monthly Service Fee</p>}
          right={<p>${payments?.[0].fees.toFixed(2)}</p>}
        />
        <LineItem
          left={
            <p>
              Due Monthly{" "}
              <button
                type="button"
                className="text-bmsblue ml-2"
                onClick={() => setExpanded((e) => !e)}
              >
                {!expanded ? "View Schedule" : "Hide Schedule"}
              </button>
            </p>
          }
          right={<p>${payments?.[0].total.toFixed(2)}</p>}
        />
        {expanded && (
          <div className="my-2">
            {payments?.map((p) => {
              return (
                <LineItem
                  left={<p>{new Date(p.due).toLocaleDateString()}</p>}
                  right={<p>${p.total.toFixed(2)}</p>}
                />
              );
            })}
          </div>
        )}
        <LineItem left={<p>Total</p>} right={<p>${total.toFixed(2)}</p>} />
      </div>
    );
  }
}

interface LineItemProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

function LineItem({ left, right }: LineItemProps) {
  return (
    <div className="flex flex-row justify-between py-1">
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
}
